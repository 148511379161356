import React, { FC } from 'react';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from '@/components/ui/hover-card';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import StatusBox from '@ui/status/StatusBox';
import { IFeedbackStatus, IIntegrationStatus } from '@all-types/csat-types';

interface IStatusInfoButtonProps {
  integrationStatus?: IIntegrationStatus['state'];
  feedbackStatus?: IFeedbackStatus;
  isRunning: boolean;
  isSuccessful: boolean;
  isFailed: boolean;
}

const StatusInfoButton: FC<IStatusInfoButtonProps> = ({
  integrationStatus,
  feedbackStatus,
  isRunning,
  isFailed,
  isSuccessful
}) => {
  return (
    <HoverCard>
      <HoverCardTrigger>
        <Badge
          variant="secondary"
          className={cn('bg-white text-lg cursor-pointer', {
            'text-red-500 border-red-500': isFailed,
            'text-green border-green': isSuccessful,
            'text-yellow-75 border-yellow-75': isRunning
          })}>
          {integrationStatus || 'Status'}
        </Badge>
      </HoverCardTrigger>
      <HoverCardContent className="w-72">
        <StatusBox
          integrationStatus={integrationStatus}
          feedbackStatuses={feedbackStatus}
        />
      </HoverCardContent>
    </HoverCard>
  );
};

export default StatusInfoButton;
