import React from 'react';
import { Link } from 'react-router-dom';
import IntegrationsDialog from '@ui/dialogs/IntegrationsDialog';
import { Upload } from '@icons';

const MainPage = () => {
  return (
    <div className="flex justify-center flex-col sm:mt-[-200px] mt-[-320px]">
      <h1 className="text-4xl font-bold text-darkCyan mb-10">
        Welcome to Unwink
      </h1>
      <p className="text-lg mb-10 sm:px-0 px-4 font-normal">
        Get started by connecting to the Data Source
      </p>
      <div className="flex flex-row gap-10 justify-center sm:px-0 px-4">
        <Link
          to="/csat"
          className="text-base bg-lightCyan hover:opacity-50 sm:w-72 sm:h-72 w-1/2 h-36 border-2 border-greenBorder rounded-lg text-darkCyan font-bold flex justify-between py-16 items-center cursor-pointer flex-col">
          <Upload /> Upload your file to begin
        </Link>
        <IntegrationsDialog />
      </div>
    </div>
  );
};

export default MainPage;
