import React from 'react';
import { Input } from '@/components/ui/input';

interface IProfileTapProps {
  email?: string;
  verified?: boolean;
  name?: string;
  nickname?: string;
  picture?: string;
}

const ProfileTab = ({
  email,
  verified,
  name,
  nickname,
  picture
}: IProfileTapProps) => {
  return (
    <div>
      <span className="text-2xl justify-start flex px-5 pt-5 pb-3">
        Profile
      </span>
      <div className="w-[calc(100%-40px)] border-t border-gray-200 ml-5"></div>
      <div className="text-left w-full p-5 flex gap-5 ">
        <img src={picture} alt="" className="rounded-[100px] h-32 w-32" />
        <div>
          <div>
            <div className="font-bold pb-2 text-sm">Name</div>
            <Input value={name} className="!h-8" disabled={true} />
            <span className="text-xs text-gray-400 pt-2">
              Your name may appear around Unwink where you contribute or are
              mentioned.
            </span>
          </div>
          <div className="pt-2">
            <div className="font-bold pb-2 text-sm flex">
              Email&nbsp;
              <div className="font-bold text-sm ">
                is
                <span className="text-darkCyan">
                  {verified ? '' : 'not'} verified
                </span>
              </div>
            </div>
            <Input value={email} className="!h-8" disabled={true} />
            <span className="text-xs text-gray-400 pt-2">
              Your email may appear around Unwink where you contribute or are
              mentioned.
            </span>
          </div>

          <div className="pt-2">
            <div className="font-bold pb-2 text-sm">Nickname</div>{' '}
            <Input value={nickname} className="!h-8" disabled={true} />
            <span className="text-xs text-gray-400 pt-2">
              Your nickname may appear around Unwink where you contribute or are
              mentioned.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileTab;
