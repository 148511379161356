import React, { FC } from 'react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import TooltipWrapper from '@ui/tooltip/TooltipWrapper';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Badge } from '@/components/ui/badge';

interface ISpaceButtonProps {
  name: string;
  type?: string;
  onClick?: () => void;
  isCurrent?: boolean;
  totalFiles?: number;
  isSpace?: boolean;
  className?: string;
  onInfoOpen?: () => void;
  isCustom?: boolean;
}

const SpaceButton: FC<ISpaceButtonProps> = ({
  name,
  type,
  onClick = () => {},
  isCurrent = false,
  totalFiles = 0,
  isSpace = true,
  className,
  onInfoOpen = () => {},
  isCustom = false
}) => {
  return (
    <div
      className={cn(
        'relative bg-white sm:min-w-56 md:min-w-72 min-w-1/2 font-inter aspect-square border-2 border-gray-300 rounded-lg flex justify-center items-center hover:opacity-100 overflow-x-hidden ',
        {
          'border-main bg-lightCyan hover:opacity-50': !isSpace,
          'border-none': !isCustom,
          [`${className}`]: !!className
        }
      )}
      onClick={!isSpace ? onClick : undefined}>
      <div className="flex flex-col break-words">
        {isSpace && (
          <div>
            {isCurrent && (
              <Badge className="absolute top-3 left-3 text-sm" variant="chichi">
                Current
              </Badge>
            )}
            <div className="absolute top-2 right-3">
              <span
                className="text-darkCyan font-bold  rounded-[100px] hover:bg-gray-100 p-2 flex items-center hover:cursor-pointer"
                onClick={onInfoOpen}>
                <BsThreeDotsVertical size={24} />
              </span>
            </div>
            {!type ? (
              <span className="absolute bottom-14 left-6 text-gray-700 font-bold text-base">
                Type: <span className="font-normal">None</span>
              </span>
            ) : type === 'File' ? (
              <span className="absolute bottom-14 left-6 text-gray-700 font-bold text-base">
                Type:{' '}
                <span className="font-normal">
                  {type} <span className="text-gray-300">({totalFiles})</span>
                </span>
              </span>
            ) : (
              <span className="absolute bottom-14 left-6 text-gray-700 font-bold text-base">
                Type:{' '}
                <span className="font-normal">
                  Integration{' '}
                  <span className="text-gray-300">({totalFiles})</span>
                </span>
              </span>
            )}
            {!isCurrent && (
              <Button
                className="absolute bottom-3 right-3 bg-main font-semibold font-sans text-sm"
                onClick={onClick}
                disabled={isCurrent}>
                Switch
              </Button>
            )}
          </div>
        )}
        <TooltipWrapper text={name}>
          <div
            className={cn('text-2xl text-primary-25 font-medium mx-4 w-64', {
              'text-darkCyan font-bold': isCustom
            })}>
            {name.length > 60 ? `${name.slice(0, 60)}...` : name}
          </div>
        </TooltipWrapper>
      </div>
    </div>
  );
};

export default SpaceButton;
