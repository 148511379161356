import React, { FC, memo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import ChartWrapper from '@ui/charts/ChartWrapper';
import HighchartsMore from 'highcharts/highcharts-more'; // Додайте цей імпорт для модуля "more"
import HighchartsGauge from 'highcharts/modules/solid-gauge'; // Додайте цей імпорт для модуля "gauge"

// initialize the module "more"
HighchartsMore(Highcharts);

// initialize the module "gauge"
HighchartsGauge(Highcharts);

interface IInterestChartProps {
  wrapperClassName?: string;
  data?: any;
  title?: string;
  isLoading?: boolean;
  width?: number; // Додайте висоту та ширину як пропси
  height?: number;
}

const InterestChart: FC<IInterestChartProps> = memo(function InterestChart({
  wrapperClassName,
  data = [80],
  title = 'Interest Chart',
  isLoading,
  width = 400,
  height = 300
}) {
  const options = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: height
    },
    title: {
      text: title,
      align: 'left',
      style: {
        color: 'rgb(107 114 128)'
      }
    },
    pane: {
      startAngle: -90,
      endAngle: 89.9,
      background: null,
      center: ['50%', '55%'],
      size: width
    },
    yAxis: {
      min: 0,
      max: 100,
      tickPixelInterval: 72,
      tickPosition: 'inside',
      tickColor: '#FFFFFF',
      tickLength: 10,
      tickWidth: 1,
      minorTickInterval: null,
      labels: {
        distance: 10,
        style: {
          fontSize: '14px'
        }
      },
      lineWidth: 0,
      plotBands: [
        {
          from: 0,
          to: 40,
          color: 'rgba(255,77,85,0.87)', // red
          thickness: 10
        },
        {
          from: 40,
          to: 80,
          color: 'rgba(255,222,0,0.85)', // yellow
          thickness: 10
        },
        {
          from: 80,
          to: 100,
          color: 'rgba(1,180,4,0.84)', // green
          thickness: 10
        }
      ]
    },
    series: [
      {
        name: title,
        data: data,
        tooltip: {
          valueSuffix: ' %'
        },
        dataLabels: {
          format: '{y} %',
          borderWidth: 0,
          color: Highcharts?.defaultOptions?.title?.style?.color || '#333333',
          style: {
            fontSize: '16px'
          }
        },
        dial: {
          radius: '80%',
          backgroundColor: 'gray',
          baseWidth: 12,
          baseLength: '0%',
          rearLength: '0%'
        },
        pivot: {
          backgroundColor: 'gray',
          radius: 6
        }
      }
    ]
  };

  return (
    <ChartWrapper className={wrapperClassName} isLoading={isLoading}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </ChartWrapper>
  );
});

export default InterestChart;
