import React, { FC } from 'react';
import { IFeedbackStatus } from '@all-types/csat-types';
import { Badge } from '@/components/ui/badge';

interface IStatusBoxProps {
  integrationStatus?: string;
  feedbackStatuses?: IFeedbackStatus;
}

const StatusBox: FC<IStatusBoxProps> = ({
  integrationStatus,
  feedbackStatuses
}) => {
  const getBadgeVariant = (status: string) => {
    if (status === 'Completed' || status === 'Waiting') {
      return 'green';
    } else if (status === 'Failed' || status === 'Stopped') {
      return 'red';
    } else if (status === 'InProgress' || status === 'Pending') {
      return 'yellow';
    } else {
      return 'default';
    }
  };

  return (
    <div className="flex flex-col text-left">
      {integrationStatus && (
        <div className="border-b-2 pb-2">
          <span className="pr-2">Integration status:</span>
          <Badge variant={getBadgeVariant(integrationStatus)}>
            {integrationStatus}
          </Badge>
        </div>
      )}
      <div className="flex flex-col">
        <div className="py-1">Feedback statuses:</div>
        {Object.entries(feedbackStatuses || {}).map(([statusName, value]) => (
          <div
            key={statusName}
            className="flex gap-1 border-b py-2 last:border-b-0">
            <span className="text-main">{statusName}:</span>
            <span>{value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusBox;
