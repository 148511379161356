import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import BarChart from '@ui/charts/BarChart';
import LineChart from '@ui/charts/LineChart';
import RaceBarChart from '@ui/charts/RaceBarChart';
import StackedBarChart from '@ui/charts/StackedBarChart';
import FileDropzone, { FileType } from '@ui/media/FileDropzone';
import Progress from '@ui/media/Progress';
import { Hand } from '@icons';
import DashboardFilters from '@ui/filters/DashbordFilters';
import { Button } from '@/components/ui/button';
import { FILE_TYPES } from '@ui/forms/Dropzone';
import InterestChart from '@ui/charts/InterestChart';

const DashboardPage = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [showAnalytics, setShowAnalytics] = useState<boolean>(false);
  const [file, setFile] = useState<FileType | null>(null);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const onFileUpload = (file: File): void => {
    console.log('file', file);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFile(file);
    setShowAnalytics(true);
  };

  const onStartUpload = async (): Promise<void> => {
    setShowProgress(true);
    setTimeout(() => {
      setProgress(0);
      setShowProgress(false);
    }, 10000);
  };

  const onFileDelete = (): void => {
    setFile(null);
  };

  const onConnect = (): void => {
    navigate('/connect');
  };

  return (
    <div className="w-full">
      <div className="ml-10">
        <div className="mb-5 flex">
          <img src={Hand} alt="abyss" className="mr-[10px]" />
          <label htmlFor="" className="text-darkBlue text-lg font-bold">
            Hey {user?.name}!
          </label>
        </div>
        <div className="flex justify-between mb-11 flex-wrap">
          <div className="text-3xl font-normal">
            You have served 30,000 visitors this month.
          </div>
        </div>
        <DashboardFilters />
      </div>
      {showAnalytics ? (
        <>
          <div className="flex gap-10 mb-10">
            <BarChart
              wrapperClassName="w-2/3"
              data={[
                {
                  name: 'Values',
                  data: [10, 15, 8, 20]
                }
              ]}
            />
            <LineChart wrapperClassName="w-1/3" data={[10, 15, 8, 20]} />
          </div>
          <div className="flex gap-10 mb-10">
            <RaceBarChart wrapperClassName="w-2/3" data={[50]} />
            <StackedBarChart wrapperClassName="w-1/3" />
          </div>
          <div className="flex gap-10 mb-10">
            <InterestChart wrapperClassName="w-1/2" data={[75]} />
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center">
          <h1 className="text-center text-2xl p-10">
            There are no analytics yet
          </h1>
          <div className="w-3/4 flex flex-row items-start justify-between gap-10">
            <div className="w-1/2">
              <Button onClick={onConnect}>Connect location`s cameras</Button>
            </div>
            <div className="w-1/2 flex-col items-center justify-between gap-10">
              <FileDropzone
                acceptedFileType={FILE_TYPES.AUDIO}
                onFileSelect={onFileUpload}
                onDeleteFile={onFileDelete}
                file={file}
                disabled={false}
              />
              {showProgress && <Progress value={progress} />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardPage;
