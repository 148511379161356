type TypesObject = {
  [key: string]: [];
};

export const imageTypes: TypesObject = {
  'image/jpeg': [],
  'image/svg+xml': [],
  'image/png': [],
  'image/gif': []
};
export const videoTypes: TypesObject = {
  'video/mp4': [],
  'video/webm': []
};

export const excelTypes: TypesObject = {
  'text/csv': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
};

export const documentTypes: TypesObject = {
  'application/pdf': [],
  'text/plain': [],
  'application/vnd.ms-powerpoint': [],
  'application/msword': [],
  ...excelTypes
};

export const audioTypes: TypesObject = {
  'audio/mpeg': [],
  'audio/ogg': [],
  'audio/wav': [],
  'audio/webm': []
};

export const types: { [key: string]: TypesObject } = {
  image: imageTypes,
  video: videoTypes,
  all: {
    ...imageTypes,
    ...videoTypes,
    ...documentTypes,
    ...audioTypes,
    ...excelTypes
  },
  audio: audioTypes,
  excel: excelTypes
};
