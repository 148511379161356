import { FC, MouseEventHandler, useCallback } from 'react';
import { cn } from '@/lib/utils';
import VideoPlayer from '@ui/media/VideoPlayer';

interface IMediaPreview {
  type: string;
  name: string;
  src: string;
  width?: string | number;
  height?: string | number;
  imageClassName?: string;
  onClick?: MouseEventHandler<HTMLImageElement>;
}

export const MediaPreview: FC<IMediaPreview> = ({
  type,
  src = '',
  name,
  width = '100%',
  height = '100%',
  imageClassName = '',
  onClick
}) => {
  const renderVideoPreview = useCallback(() => {
    return (
      <>
        <VideoPlayer src={src} width={width} height={height} controls loop />
      </>
    );
  }, [src, width, height]);

  const renderImagePreview = useCallback(() => {
    return (
      <div className="w-full h-full relative">
        <img
          onClick={onClick}
          alt={name}
          src={src}
          className={cn(['object-contain w-full h-full', imageClassName])}
        />
      </div>
    );
  }, [src, name, imageClassName]);

  return (
    <>
      {type.includes('video') && renderVideoPreview()}
      {type.includes('image') && renderImagePreview()}
    </>
  );
};

export default MediaPreview;
