import React, { FC, useState } from 'react';
import { FaCheck } from 'react-icons/fa6';
import { FaSort } from 'react-icons/fa';
import { cn } from '@/lib/utils';
import { Button, ButtonVariant } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import { Option } from '@all-types/ui-types';

interface IMapsFieldsComboboxProps {
  options: Option[];
  onChange: (value: Option | null) => void;
  selected?: string;
}

const MapsFieldsCombobox: FC<IMapsFieldsComboboxProps> = ({
  options,
  selected = '',
  onChange
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(selected);

  const onValueChange = (currentValue: string) => {
    const newValue = currentValue === value ? '' : currentValue;
    setValue(newValue);
    onChange(
      newValue ? options.find((option) => option.value === newValue)! : null
    );
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={ButtonVariant.OUTLINE}
          role="combobox"
          aria-expanded={open}
          className="w-[400px] justify-between">
          {value
            ? options.find((option) => option.value === value)?.label
            : 'Select...'}
          <FaSort className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command>
          <CommandInput placeholder="Select..." className="h-9" />
          <CommandEmpty>No maps fields found.</CommandEmpty>
          <CommandGroup className="h-[400px] overflow-y-auto">
            {options.map((option) => (
              <CommandItem
                key={option.label}
                value={option.value}
                onSelect={onValueChange}>
                {option.label}
                <FaCheck
                  className={cn(
                    'ml-auto h-4 w-4',
                    value === option.value ? 'opacity-100' : 'opacity-0'
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default MapsFieldsCombobox;
