import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { FC } from 'react';

interface ISwitchProps {
  title?: string;
  checked?: boolean;
  onChange?: () => void;
}

const Switcher: FC<ISwitchProps> = ({
  title,
  checked,
  onChange
}: ISwitchProps) => {
  return (
    <div className="flex items-center space-x-2">
      <Label htmlFor="airplane-mode">{title}</Label>
      <Switch id="airplane-mode" checked={checked} onChange={onChange} />
    </div>
  );
};
export default Switcher;
