import React from 'react';
import { cn } from '@/lib/utils';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import { Button, ButtonVariant } from '@/components/ui/button';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { BsDatabaseFillCheck } from 'react-icons/bs';
import { SiNintendogamecube } from 'react-icons/si';
import { MdVerifiedUser } from 'react-icons/md';

const notifications = [
  {
    icon: <BsDatabaseFillCheck size={24} />,
    message: 'Data on the space "Intercom" has been processed',
    time: '1 hour ago'
  },
  {
    icon: <MdVerifiedUser size={24} />,
    message: 'Change of personal data is successful',
    time: '1 week ago'
  },
  {
    icon: <SiNintendogamecube size={24} />,
    message: 'The space "Default" has been created',
    time: '1 month ago'
  }
];

const Notifications = () => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={ButtonVariant.GHOST}
          className="hover:bg-gray-75 rounded-[100px] !p-2">
          <IoMdNotificationsOutline size={24} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80">
        {notifications.map((notification, index) => (
          <div
            key={index}
            className={cn('flex items-center', {
              'pb-4': index !== notifications.length - 1
            })}>
            <div>{notification.icon}</div>
            <div className="pl-3">
              <div className="text-left text-sm text-primary-25 pb-2">
                {notification.message}
              </div>
              <div className="text-left text-gray-300 text-xs">
                {notification.time}
              </div>
            </div>
          </div>
        ))}
      </PopoverContent>
    </Popover>
  );
};

export default Notifications;
