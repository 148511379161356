import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getColumns, getTableData } from '@utils/tableHelper';
import { ObjectType } from '@all-types/ui-types';
import CsatAPI from '@api/csatAPI';
import { SpaceContext, SpaceContextType } from '@context/contexts';
import { IFilters } from '@all-types/csat-types';

interface ITableDataProps {
  appliedFilters?: Partial<IFilters>;
  triggerRefetch?: boolean;
}

const useTableData = ({ appliedFilters, triggerRefetch }: ITableDataProps) => {
  const csatAPI = CsatAPI.getInstance();
  const { dataSchema, currentSpace } =
    useContext<SpaceContextType>(SpaceContext);
  const currentSpaceId = currentSpace?.id;

  const [columns, setColumns] = useState<any[]>([]);
  const [tableData, setTableData] = useState<ObjectType[]>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 10 //default page size
  });

  const { data: feedbackResponse, refetch: refetchFeedbacks } = useQuery({
    queryKey: [
      'feedbacks',
      currentSpaceId,
      appliedFilters,
      pagination.pageIndex,
      pagination.pageSize
    ],
    queryFn: () =>
      csatAPI.getFeedbacks(
        currentSpaceId as string,
        appliedFilters,
        pagination.pageIndex,
        pagination.pageSize
      ),
    enabled: !!currentSpaceId
  });

  useEffect(() => {
    const data = feedbackResponse?.items;
    if (dataSchema && data?.length) {
      const columnsData = getColumns(data, dataSchema);
      const tableData = getTableData(data, dataSchema);
      setColumns(columnsData);
      setTableData(tableData);
    } else {
      setColumns([]);
      setTableData([]);
    }
  }, [dataSchema, feedbackResponse]);

  const refetchAll = async () => {
    await refetchFeedbacks();
  };

  useEffect(() => {
    if (triggerRefetch) {
      refetchAll();
    }
  }, [triggerRefetch]);

  return {
    columns,
    tableData,
    pagination,
    refetchFeedbacks,
    rowCount: feedbackResponse?.total || 0,
    setPagination
  };
};

export default useTableData;
