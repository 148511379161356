import axios from 'axios';
import { toaster } from '@ui/dialogs/ToastErrorMessage';
import { IFilePreviewResponse } from '@all-types/csat-types';

const apiUrl = process.env.REACT_APP_API_URL as string;

export default class CsatAPI {
  private static instance: CsatAPI;
  private host = apiUrl;

  private api: any;

  private constructor() {
    const token = localStorage.getItem('auth-token');
    this.api = axios.create({
      baseURL: this.host,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }

  public static getInstance(): CsatAPI {
    if (!CsatAPI.instance) {
      CsatAPI.instance = new CsatAPI();
    }

    return CsatAPI.instance;
  }

  public async uploadFile(fileUploadUrl: string, formData: any): Promise<any> {
    const response = await axios.put(fileUploadUrl, formData).catch(function (
      error: any
    ) {
      toaster.error({ text: 'Cannot upload file' });
      console.error(error.toJSON());
    });
    if ([200, 201].includes(response!.status!)) {
      console.info('Upload file response', response!.data);
      return response;
    }
    return null;
  }

  public async addFileForProcessing(
    spaceId: string,
    s3FileName: string,
    fileName: string
  ): Promise<{ fileId: string } | null> {
    const response = await this.api
      .post(`/file/${spaceId}/${s3FileName}/add-file`, { fileName })
      .catch((error: any) => {
        toaster.error({ text: 'Cannot add file for processing' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Added file for processing', response.data);
      return response.data;
    }
    return null;
  }

  public async validateFileStructure(
    spaceId: string,
    s3FileName: string
  ): Promise<IFilePreviewResponse | string | null> {
    try {
      const response = await this.api.post(
        `/file/${spaceId}/${s3FileName}/preview`
      );
      if ([200, 201].includes(response?.status)) {
        console.info('File structure validated', response.data);
        return response.data;
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || 'Cannot validate file structure';
      toaster.error({ text: 'Cannot validate file structure' });
      console.error(error.toJSON());
      return errorMessage;
    }
    return null;
  }

  public async getUploadURL(
    spaceId: string,
    fileExt: string
  ): Promise<{ url: string; s3FileName: string } | null> {
    const response = await this.api
      .post(`/file/${spaceId}/get-upload-url`, { fileExt })
      .catch((error: any) => {
        toaster.error({ text: 'Cannot get file URL' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Uploaded file data', response.data);
      return response.data;
    }
    return null;
  }

  public async getFiles(spaceId?: string): Promise<any | null> {
    const response = await this.api
      .get(`/file/${spaceId}/list`)
      .catch((error: any) => {
        toaster.error({ text: 'Cannot get uploaded files' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Uploaded files', response.data.items);
      return response.data.items;
    }
    return null;
  }
}
