import React, { useContext, useLayoutEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import FileAPI from '@api/fileAPI';
import { SpaceContext, SpaceContextType } from '@context/contexts';
import FileUploadSection from '@ui/upload-page-components/FileUploadSection';
import RecentlyUploaded from '@ui/upload-page-components/RecentlyUploaded';
import useFeedbackFileUpload from '@hooks/useFeedbackFileUpload';
import Spinner from '@ui/layout/Spinner';
import LoadingDialog from '@ui/dialogs/LoadingDialog';
import AlertDialogMessage from '@ui/dialogs/AlertDialogMessage';

const CsatPage = () => {
  const fileAPI = FileAPI.getInstance();
  const navigate = useNavigate();
  const { currentSpace, dataSchema, fileFields, refetchCurrentSpace } =
    useContext<SpaceContextType>(SpaceContext);

  const currentSpaceId = currentSpace?.id as string;

  useLayoutEffect(() => {
    if (currentSpace?.type === 'Intercom') {
      navigate(`/csat/${currentSpaceId}`);
    }
  }, []);

  const onSuccess = (fileId: string) => {
    navigate(`/csat/${fileId}`);
  };

  const {
    onUploadFile,
    cancelUpload,
    isFileProcessing,
    fileIsValid,
    uploadFileAndValidate,
    showColumnsAlert,
    closeColumnsAlert,
    previewErrorMessage
  } = useFeedbackFileUpload({
    currentSpaceId,
    dataSchema,
    onSuccess,
    refetchCurrentSpace
  });

  const { data: files } = useQuery({
    queryKey: ['files', currentSpaceId],
    queryFn: () => fileAPI.getFiles(currentSpaceId),
    enabled: !!currentSpaceId
  });

  const filesSection = useMemo(() => {
    return files?.length > 0 && <RecentlyUploaded files={files} />;
  }, [files]);

  return (
    <div className="sm:w-full w-screen relative flex justify-around pt-4">
      <div className="px-12 sm:px-0 lg:w-1/2 sm:w-2/3 w-full">
        {filesSection}
        <FileUploadSection
          fileFields={fileFields}
          dataSchema={dataSchema}
          onFileSubmit={onUploadFile}
          uploadFileAndValidate={uploadFileAndValidate}
          isValid={fileIsValid}
        />
        {isFileProcessing && (
          <LoadingDialog
            onClick={cancelUpload}
            isOpen={true}
            title="We're uploading your file and running it through our model"
            buttonTitle="Cancel"
            description={<Spinner />}
          />
        )}
        {showColumnsAlert && (
          <AlertDialogMessage
            title="The file columns do not match the space schema!"
            description={
              <div className="flex flex-col">
                <span>{previewErrorMessage}</span>
              </div>
            }
            isOpen={true}
            onClick={closeColumnsAlert}
          />
        )}
      </div>
    </div>
  );
};

export default CsatPage;
