import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';
import { IoStar } from 'react-icons/io5';
import { cn } from '@/lib/utils';
import { Button, ButtonVariant } from '@/components/ui/button';
import { TableType } from '@utils/tableType';
import { Badge } from '@/components/ui/badge';
import { dateStringFromTimestamp, FULL_DATE_FORMAT } from '@utils/time';
import { ObjectType } from '@all-types/ui-types';
import { IFileSchema } from '@all-types/csat-types';
import { WordColorByType } from '@const/defaultWorldColor';
import TooltipWrapper from '@ui/tooltip/TooltipWrapper';

const getFieldBySchema = (key: string, dataSchema: IFileSchema): string => {
  return dataSchema[key] || key;
};

const getColumnName = (column: string, dataSchema: IFileSchema): string => {
  const columnName = getFieldBySchema(column, dataSchema);
  return columnName.charAt(0).toUpperCase() + columnName.slice(1);
};

const orderColumns = (columns: string[], dataSchema: IFileSchema): string[] => {
  const orderedColumns = [
    'text',
    dataSchema.agent,
    dataSchema.rating,
    'sentiment',
    'category',
    'intent',
    dataSchema.team,
    dataSchema.link,
    'tags',
    dataSchema.date
  ];
  return orderedColumns.filter((column) => columns.includes(column));
};

export const getColumns = (
  data: ObjectType[],
  dataSchema: IFileSchema
): ColumnDef<TableType>[] => {
  const filteredColumns = Object.keys(data[0]).filter(
    (column) => column !== 'id'
  );
  const orderedColumns = orderColumns(filteredColumns, dataSchema);
  const columns = orderedColumns.map((column) =>
    getFieldBySchema(column, dataSchema)
  );
  const dateColumn = columns
    .find((column) => dataSchema.date === column)
    ?.toLowerCase();
  const ratingColumn = columns
    .find((column) => dataSchema.rating === column)
    ?.toLowerCase();
  return columns.map((col) => ({
    accessorKey: col,
    header: ({ column }) => {
      return (
        <div
          className={cn('flex items-center', {
            'w-72': col === 'text',
            'min-w-[10rem]': [
              'category',
              'tags',
              'agentName',
              dataSchema.date
            ].includes(col)
          })}>
          {/*{index === 0 && (*/}
          {/*  <Checkbox*/}
          {/*    checked={table.getIsAllPageRowsSelected()}*/}
          {/*    onCheckedChange={(value) =>*/}
          {/*      table.toggleAllPageRowsSelected(!!value)*/}
          {/*    }*/}
          {/*    aria-label="Select all"*/}
          {/*  />*/}
          {/*)}*/}
          <Button
            variant={ButtonVariant.GHOST}
            onClick={column.getToggleSortingHandler()}>
            {getColumnName(col, dataSchema)}
            <ArrowUpDown className="h-4 w-4" />
          </Button>
        </div>
      );
    },
    cell: (data) => {
      const column = data.column.id.toLowerCase();
      const value = data.getValue();
      if (column === 'tags') {
        const tags = value as string[];
        const filteredTags = tags.filter((tag) => !!tag);
        return (
          <div className="ml-4 flex flex-wrap gap-2">
            {filteredTags?.length > 0 &&
              filteredTags.map((tag: string) => (
                <Badge variant="chichi" key={tag}>
                  {tag}
                </Badge>
              ))}
          </div>
        );
      } else if (column === 'link' || column.includes('url')) {
        return (
          <TooltipWrapper text={value as string}>
            <a
              rel="noreferrer"
              href={value as string}
              target="_blank"
              className="inline-block max-w-[180px] overflow-hidden text-ellipsis whitespace-nowrap py-2 px-4">
              {value as string}
            </a>
          </TooltipWrapper>
        );
      } else if (column === dateColumn) {
        return (
          <div className="capitalize ml-4 flex flex-col gap-2">
            {dateStringFromTimestamp(value as number, FULL_DATE_FORMAT)}
          </div>
        );
      } else if (column === ratingColumn) {
        const count = Number(value);
        if (count > 5) {
          return count;
        }
        const stars = Array.from({ length: count }, (_, i) => (
          <IoStar key={i} className="text-yellow-75 h-5 w-5 mx-1" />
        ));
        return <div className="ml-4 flex flex-row gap-1">{stars}</div>;
      } else if (column === 'sentiment') {
        const sentimentColor = WordColorByType[value as string] || '#000000';
        return (
          <Badge
            variant="secondary"
            className="bg-white ml-4"
            style={{ color: sentimentColor, borderColor: sentimentColor }}>
            {value as string}
          </Badge>
        );
      }
      return (
        <div className="capitalize ml-4 flex flex-col gap-2">
          {value as string}
        </div>
      );
    }
  }));
};

export const getTableData = (
  data: ObjectType[],
  dataSchema: IFileSchema
): ObjectType[] => {
  return data.map((item: any) => {
    const row: ObjectType = {};
    Object.keys(item).forEach((key) => {
      const field = getFieldBySchema(key, dataSchema);
      row[field] = item[key];
    });
    return row;
  });
};
