import React from 'react';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Button, ButtonVariant } from '@/components/ui/button';
import { FaRegTrashAlt } from 'react-icons/fa';

interface IConfirmationModalProps {
  title?: string;
  onDelete: () => void;
  hideRemoveLabel?: boolean;
}

const ConfirmationModal = ({
  title = 'Default',
  onDelete,
  hideRemoveLabel = false
}: IConfirmationModalProps) => {
  return (
    <Dialog>
      <DialogTrigger className="flex items-center hover:opacity-50 text-red-500">
        {!hideRemoveLabel && <span className="break-all pr-3">Remove</span>}
        <FaRegTrashAlt size={hideRemoveLabel ? 24 : 16} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="text-md">{title}</DialogTitle>
        </DialogHeader>
        <DialogFooter>
          <DialogClose className="flex gap-3">
            <Button variant={ButtonVariant.OUTLINE}>Cancel</Button>
            <Button onClick={onDelete}>Delete</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
