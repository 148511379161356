import { FC } from 'react';
import { cn } from '@/lib/utils';

interface IProgress {
  value: number;
  className?: string;
  showPercentage?: boolean;
}

const Progress: FC<IProgress> = ({
  value,
  className,
  showPercentage = true
}) => {
  const percentage = `${value}%`;
  return (
    <div className={cn(['flex gap-3 items-baseline', className])}>
      <div className="w-full bg-lightCyan rounded-full h-2.5 mb-4">
        <div
          className="bg-main h-2.5 rounded-full"
          style={{ width: percentage }}
        />
      </div>
      {showPercentage && (
        <span className="text-gray-700 text-sm">{percentage}</span>
      )}
    </div>
  );
};

export default Progress;
