import React, { FC } from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

interface ITooltipWrapperProps {
  children: React.ReactNode;
  className?: string;
  text?: string;
}
const TooltipWrapper: FC<ITooltipWrapperProps> = ({
  children,
  text,
  className
}) => {
  return (
    <div>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>{children}</TooltipTrigger>
          <TooltipContent className={cn('whitespace-nowrap', className)}>
            {text}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export default TooltipWrapper;
