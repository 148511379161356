import React, { FC, useState } from 'react';
import { cn } from '@/lib/utils';
import Spinner from '@ui/layout/Spinner';
import { Button, ButtonVariant } from '@/components/ui/button';
import ChatWrapper from '@ui/chat/ChatWrapper';
import { MessageType } from '@hooks/useInsights';

interface IInsightsProps {
  messages: MessageType[];
  insights: any;
  isInsightsLoading: boolean;
  refetchInsights: () => void;
  sendMessage: (question: string) => void;
  isInsightsDisabled?: boolean;
}

const InsightsTab: FC<IInsightsProps> = ({
  messages,
  insights,
  isInsightsLoading,
  refetchInsights,
  sendMessage,
  isInsightsDisabled = false
}) => {
  const [question, setQuestion] = useState<string>('');

  const handleAskButtonClick = async () => {
    await refetchInsights();
  };

  const onSendMessage = async () => {
    await sendMessage(question);
  };

  const onMessageChange = (value: string) => {
    setQuestion(value);
  };

  const getInsightsSection = (sectionData: string | string[]) => {
    return Array.isArray(sectionData) ? (
      <ul className="text-left list-disc pl-6">
        {sectionData.map((item: any, index: number) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    ) : (
      <div className="text-left">{sectionData}</div>
    );
  };

  const getPromptSection = () => {
    return (
      <div className="w-1/2 gap-4 pl-8 h-[calc(100vh-218px)]">
        <ChatWrapper
          onSendMessage={onSendMessage}
          messages={messages}
          onChange={onMessageChange}
          isDisabled={isInsightsDisabled}
        />
      </div>
    );
  };

  if (typeof insights === 'string') {
    return (
      <div className="bg-white w-full flex flex-row gap-10 px-8 py-4 border border-gray-100">
        <div className="w-3/5 border-r border-r-gray-100 pr-8">
          <div className="flex flex-col items-start gap-4">
            <div className="font-bold">Summary:</div>
            <div className="text-left">{`${insights}`}</div>
          </div>
        </div>
        {getPromptSection()}
      </div>
    );
  }

  return (
    <div className="bg-white w-full flex gap-5 justify-between px-8 py-4 border border-gray-100">
      <div className="flex flex-row w-full">
        <div className="flex flex-col w-1/2 gap-8 border-r border-r-gray-100 pr-8 overflow-y-auto h-[calc(100vh-218px)]">
          {insights || (
            <div className="bg-white w-full h-full flex flex-col justify-center items-center">
              <p className="text-gray-700 text-2xl w-full pb-8">
                Use our
                <span className="text-bold text-darkCyan">
                  &nbsp;Get Insights&nbsp;
                </span>
                functionality to obtain executive summaries and valuable
                insights.
              </p>
              <div className="flex justify-center">
                <Button
                  disabled={isInsightsLoading || isInsightsDisabled}
                  variant={ButtonVariant.DEFAULT}
                  onClick={() => handleAskButtonClick()}>
                  {isInsightsLoading && <Spinner size="w-4 h-4" />}
                  <span className={cn(isInsightsLoading && 'pr-6')}>
                    Get Insights
                  </span>
                </Button>
              </div>
            </div>
          )}
        </div>
        {getPromptSection()}
      </div>
    </div>
  );
};

export default InsightsTab;
