import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DemoAPI from '@api/demoAPI';
import { FILE_TYPES } from '@ui/forms/Dropzone';
import FileDropzone, { FileType } from '@ui/media/FileDropzone';
import { Button } from '@/components/ui/button';
import Progress from '@ui/media/Progress';

const demoFiles = [
  {
    id: 'de43a774-17dc-436d-a003-9ee336492112.mp3',
    name: 'Sales Call example 1.mp3'
  },
  {
    id: 'a81420ce-e4ac-44d4-8d7b-60373072c131.mp3',
    name: 'Sample Order Taking _ Customer Support Philippines.mp3'
  },
  {
    id: 'ae67df45-0bfe-4a44-880a-c0e84346a29f.mp3',
    name: 'Call Center Sample Calls_ Product Recall Hotline'
  }
];

const ConversationPage = () => {
  const [file, setFile] = useState<FileType | null>(null);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const navigate = useNavigate();
  const demoAPI = DemoAPI.getInstance();

  const getTranscription = async (fileName: string): Promise<void> => {
    const intervalId = setInterval(async () => {
      const response = await demoAPI.getTranscription(fileName);
      if (response?.text) {
        setShowProgress(false);
        clearInterval(intervalId);
        navigate(`/conversation/${fileName}`);
      }
    }, 3000);
  };

  const onFileUpload = async (file: File): Promise<void> => {
    console.log('file', file);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFile(file);
  };

  const onStartUpload = async (): Promise<void> => {
    console.log('file', file);
    const formData = new FormData();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formData.append('file', file);
    setShowProgress(true);
    calculateProgress();
    const response = await demoAPI.uploadFile(formData);
    if (response) {
      await getTranscription(response.fileName);
    }
  };

  const onFileDelete = (): void => {
    setFile(null);
  };

  const calculateProgress = (): void => {
    for (let i = 0; i <= 990; i++) {
      const timeoutId = setTimeout(
        () => {
          setProgress(i / 10);
        },
        10 * (i + 1)
      );
      if (i === 990) {
        clearTimeout(timeoutId);
        break;
      }
    }
  };

  const onAudioSelect = (fileName: string): void => {
    navigate(`/conversation/${fileName}`);
  };

  const fileUploadBlock = useMemo(
    () => (
      <div className="flex flex-col items-center">
        <h1 className="sm:text-start text-center text-2xl sm:p-10 pb-10">
          Please upload your file to begin
        </h1>
        <div className="w-full flex flex-col items-center justify-between gap-10">
          <div className="flex w-full flex-col items-center justify-between gap-4">
            <FileDropzone
              acceptedFileType={FILE_TYPES.AUDIO}
              onFileSelect={onFileUpload}
              onDeleteFile={onFileDelete}
              file={file}
              disabled={false}
            />

            <Button
              className="px-4 self-end"
              disabled={!file}
              onClick={onStartUpload}>
              Upload
            </Button>
          </div>
          <hr className="w-full h-1 bg-main" />
          <div className="flex flex-col w-full">
            <h2 className="text-center sm:text-start text-xl mb-4 font-bold">
              You can also choose from demo files
            </h2>
            <div className="flex flex-col gap-4">
              {demoFiles.map((file) => (
                <div
                  key={file.id}
                  className="flex flex-col justify-center sm:flex sm:justify-between sm:flex-row sm:items-center">
                  <span className="pb-4 sm:pb-0">{file.name}</span>
                  <Button
                    className="sm:px-4 sm:self-end self-center"
                    disabled={!file}
                    onClick={() => onAudioSelect(file.id)}
                    rounded="full">
                    Choose
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    ),
    [file]
  );

  return (
    <div className="sm:w-full w-screen relative flex justify-around pt-4">
      <div className="px-12 sm:px-0 lg:w-1/2 sm:w-2/3 w-full">
        {showProgress ? (
          <div className="flex justify-center">
            <div className="flex flex-col mt-5 gap-5 py-10 w-full items-start px-4">
              <h2 className="font-bold">
                We&apos;re uploading your file and running it through our model
              </h2>
              <Progress
                className="w-full"
                value={progress}
                showPercentage={false}
              />
              <span>Usually it take up to 10 seconds</span>
            </div>
          </div>
        ) : (
          <div className="">{fileUploadBlock}</div>
        )}
      </div>
    </div>
  );
};

export default ConversationPage;
