import { useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'sonner';
import { SpaceContext, SpaceContextType } from '@context/contexts';
import SpaceAPI from '@api/spaceAPI';
import { IFeedbackStatus, IIntegrationStatus } from '@all-types/csat-types';

interface IProcessStatus {
  isConnected: boolean;
}

const useProcessStatus = ({ isConnected }: IProcessStatus) => {
  const spaceAPI = SpaceAPI.getInstance();
  const [intervalId, setIntervalId] = useState<any>(undefined);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const { currentSpace } = useContext<SpaceContextType>(SpaceContext);

  const { data: processStatus, refetch } = useQuery({
    queryKey: ['process', currentSpace?.id],
    queryFn: async (): Promise<{
      feedbackStatuses?: IFeedbackStatus;
      integrationStatus?: IIntegrationStatus['state'];
      integrationState: { [K in IIntegrationStatus['state']]: boolean };
    }> => {
      const response = await spaceAPI.getSpaceProcessStatus(
        currentSpace?.id as string
      );
      return {
        feedbackStatuses: response?.feedbackStatuses,
        integrationStatus: response?.integrationStatus?.state,
        integrationState: generateProcessStatusCheckers(
          response?.integrationStatus?.state
        )
      };
    },
    enabled: isConnected && !!currentSpace?.id
  });

  const feedbackStatus = useMemo(
    () => processStatus?.feedbackStatuses,
    [processStatus]
  );
  const integrationState = useMemo(
    () => processStatus?.integrationState,
    [processStatus]
  );

  const invokeLoadingNotification = useCallback(() => {
    toast.loading('We are fetching and analysing.', {
      description: 'This may take a few minutes.',
      className: '!bg-lightCyan !border-none !text-primary-25',
      classNames: {
        cancelButton: '!bg-main !text-white'
      },
      duration: Infinity,
      id: 'loading',
      cancel: {
        label: 'Close',
        onClick: () => {
          toast.dismiss('loading');
        }
      }
    });
  }, []);

  const invokeErrorNotification = useCallback(() => {
    toast.error('Some issue occurred while processing feedbacks.', {
      description: 'Please try again later.',
      id: 'error',
      duration: 10000
    });
  }, []);

  const isProcessing = useMemo(
    () =>
      feedbackStatus &&
      ['Pending', 'InProgress'].some(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (s) => typeof feedbackStatus[s] === 'number' && feedbackStatus[s] > 0
      ),
    [feedbackStatus]
  );

  useEffect(() => {
    if (integrationState) {
      if (isProcessing || integrationState.InProgress) {
        invokeLoadingNotification();
      }
      if (integrationState.Failed || integrationState.Stopped) {
        invokeErrorNotification();
      }
    }
  }, [isProcessing, integrationState]);

  const performCleanup = useCallback(() => {
    clearTimeout(intervalId);
    ['loading', 'error'].forEach(toast.dismiss);
  }, [intervalId]);

  useEffect(() => {
    return performCleanup;
  }, [performCleanup]);

  const startFetchingProcessStatus = () => {
    const timerId = setInterval(async () => {
      await refetch();
    }, 3000);
    setIntervalId(timerId);
  };

  useEffect(() => {
    if (
      !intervalId &&
      (isProcessing ||
        (integrationState &&
          (integrationState.Pending || integrationState.InProgress)))
    ) {
      startFetchingProcessStatus();
    }
  }, [integrationState, intervalId]);

  const isReady = !!(feedbackStatus?.Completed && feedbackStatus.Completed > 0);
  const isRunning =
    integrationState?.InProgress ||
    integrationState?.Pending ||
    !!(feedbackStatus?.Pending && feedbackStatus.Pending > 0) ||
    !!(feedbackStatus?.Fetching && feedbackStatus.Fetching > 0) ||
    !!(feedbackStatus?.InProgress && feedbackStatus.InProgress > 0);
  const isFailed = integrationState?.Failed || !!feedbackStatus?.Failed;
  const isSuccessful = isReady && !isRunning && !isFailed;

  useEffect(() => {
    if (intervalId && !isRunning && isReady && !feedbackStatus?.Pending) {
      setTriggerRefetch(true);
      performCleanup();
    }
  }, [feedbackStatus, intervalId, performCleanup, isRunning, isReady]);

  return {
    feedbackStatus,
    integrationStatus: processStatus?.integrationStatus,
    isReady,
    isRunning,
    isFailed,
    isSuccessful,
    triggerRefetch,
    startFetchingProcessStatus,
    ...integrationState
  };
};

function generateProcessStatusCheckers(
  integrationStatus: IIntegrationStatus['state'] | undefined
): { [K in IIntegrationStatus['state']]: boolean } {
  return {
    InProgress: integrationStatus === 'InProgress',
    Failed: integrationStatus === 'Failed',
    Stopped: integrationStatus === 'Stopped',
    Completed: integrationStatus === 'Completed',
    Waiting: integrationStatus === 'Waiting',
    Pending: integrationStatus === 'Pending'
  };
}

export default useProcessStatus;
