import { useEffect, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { cn } from '@/lib/utils';

export interface ISelectOption {
  value: string;
  label: string;
}
interface ISelectorProps {
  placeholder?: string;
  options: ISelectOption[];
  onChange?: (value: string) => void;
  className?: string;
  disabled?: boolean;
  defaultValue?: string;
}

const Selector = ({
  placeholder = 'variants',
  options,
  onChange,
  className,
  disabled = false,
  defaultValue
}: ISelectorProps) => {
  const [selected, setSelected] = useState<string | undefined>(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  const onValueChange = (value: string) => {
    setSelected(value);
    onChange?.(value);
  };

  return (
    <div>
      <Select
        value={selected}
        defaultValue={defaultValue}
        onValueChange={onValueChange}
        disabled={disabled}>
        <SelectTrigger
          className={cn(
            'md:w-[180px] w-[45vw] sm:w-[30vw] md:h-9 h-8 disabled:pointer-events-none',
            className
          )}>
          <div className="truncate ...">
            <SelectValue placeholder={placeholder} />
          </div>
        </SelectTrigger>
        <SelectContent>
          {options.map((item: ISelectOption) => (
            <SelectItem
              key={item.value}
              value={item.value}
              className="hover:cursor-pointer md:w-[180px] w-[45vw] sm:w-[30vw] block truncate ...">
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default Selector;
