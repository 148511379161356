import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'sonner';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import history from './utils/history';
import './index.css';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const onRedirectCallback = (appState?: AppState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: 'https://api.unwink.ai/'
      }}
      skipRedirectCallback={window.location.pathname === '/auth-intercom'}
      onRedirectCallback={onRedirectCallback}>
      <QueryClientProvider client={queryClient}>
        <App />
        <Toaster
          richColors
          closeButton={false}
          dir="ltr"
          duration={10000}
          invert={true}
          visibleToasts={5}
          expand={false}
        />
      </QueryClientProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
