import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import Hotjar from '@hotjar/browser';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { RouterProvider } from 'react-router-dom';
import Spinner from '@ui/layout/Spinner';
import router from '@router/index';
import { SpaceContext } from '@context/contexts';
import useSpaces from '@hooks/useSpaces';
import useAuthentication from '@hooks/useAuthentication';
import './App.css';

const siteId = 3890856;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);
ReactGA.initialize(process.env.REACT_APP_TRACKING_ID as string);

function App() {
  const { isAuthenticated, isLoading, authError } = useAuthentication();
  const {
    spaces,
    currentSpace,
    refetchCurrentSpace,
    dataSchema,
    fileFields,
    refetchSpaces,
    onCreateSpace,
    onSpaceChange,
    onDeleteSpace
  } = useSpaces(isAuthenticated);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search
    });
  }, []);

  if (authError) {
    return <div>Oops... {authError.message}</div>;
  }

  if (isLoading || !isAuthenticated) {
    return (
      <div className="h-full py-16">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="App h-screen">
      <SpaceContext.Provider
        value={{
          spaces,
          currentSpace,
          dataSchema,
          fileFields,
          refetchSpaces,
          refetchCurrentSpace,
          onCreateSpace,
          onSpaceChange,
          onDeleteSpace
        }}>
        <RouterProvider router={router} />
      </SpaceContext.Provider>
    </div>
  );
}

export default withAuthenticationRequired(App, {
  onRedirecting: () => <Spinner className="!h-screen" />
});
