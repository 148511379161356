import React, { FC, memo, useCallback, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ChartWrapper from '@ui/charts/ChartWrapper';
import { calculatePercentage } from '@utils/numbers';

interface IRaceBarChartProps {
  wrapperClassName?: string;
  data?: any;
  title?: string;
  yAxisLabel?: string;
  stacking?: string;
  categories?: string[] | number[];
  isLoading?: boolean;
  showPercentLabel?: boolean;
}

const RaceBarChart: FC<IRaceBarChartProps> = memo(function RaceBarChart({
  wrapperClassName,
  data = [],
  title = 'Race Bar Chart',
  yAxisLabel = 'Value',
  stacking = 'normal',
  categories,
  isLoading,
  showPercentLabel = false
}) {
  const chartType = stacking === 'percent' ? 'bar' : 'column';

  const labelsFormat =
    stacking === 'percent' ? '{point.percentage:.0f}%' : undefined;

  const yLabels = stacking === 'percent' ? '{value}%' : undefined;

  const formatLabel = useCallback(
    function (
      this: Highcharts.PointLabelObject
    ): string | number | undefined | null {
      if (showPercentLabel && this.y) {
        const totalValues = data.reduce(
          (acc: number, series: { data: number[] }) => {
            return (
              acc +
              series.data.reduce(
                (innerAcc: number, value: number) => innerAcc + value,
                0
              )
            );
          },
          0
        );
        const percentage = calculatePercentage(this.y, totalValues);
        return `${this.y} (${percentage}%)`;
      }
      return this.y;
    },
    [data, stacking]
  );

  const options = useMemo(
    () => ({
      chart: {
        type: 'bar'
      },
      title: {
        text: title,
        align: 'left',
        style: {
          color: 'rgb(107 114 128)'
        }
      },
      xAxis: {
        categories: categories || [] // Categories for each group of people (e.g., names)
      },
      yAxis: {
        title: {
          text: yAxisLabel // Y-axis label
        },
        labels: {
          format: yLabels
        }
      },
      series: data,
      plotOptions: {
        [chartType]: {
          stacking,
          dataLabels: {
            enabled: true
          }
        },
        series: {
          groupPadding: 0,
          pointPadding: 0.1,
          borderWidth: 0,
          borderRadius: 0,
          opacity: 0.9,
          colorByPoint: false,
          dataSorting: {
            matchByName: true
          },
          dataLabels: {
            enabled: true,
            format: labelsFormat,
            formatter: formatLabel
          }
        }
      } // Data series for each group of people (e.g., values)
    }),
    [
      data,
      title,
      yAxisLabel,
      stacking,
      categories,
      labelsFormat,
      chartType,
      formatLabel
    ]
  );

  return (
    <ChartWrapper className={wrapperClassName} isLoading={isLoading}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </ChartWrapper>
  );
});

export default RaceBarChart;
