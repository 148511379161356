import { useQuery } from '@tanstack/react-query';
import SpaceAPI from '@api/spaceAPI';
import { toaster } from '@ui/dialogs/ToastErrorMessage';
import { ISpace } from '@all-types/csat-types';
import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface IUseSpaceSettingsProps {
  spaceId: string | null;
  space?: ISpace | null;
}

const useSpaceSettings = ({ spaceId, space }: IUseSpaceSettingsProps) => {
  const spaceAPI = SpaceAPI.getInstance();

  const { user } = useAuth0();

  const { data: spaceSettings, refetch: refetchSpaceSettings } = useQuery({
    queryKey: ['space settings', spaceId],
    queryFn: () => spaceAPI?.getSpaceSettings(spaceId!),
    enabled: !!spaceId
  });

  const onAddParticipant = async (email: string) => {
    if (!spaceId) {
      return false;
    }
    const response = await spaceAPI.addSpaceParticipant(spaceId, email);
    if (response) {
      toaster.success({
        title: 'Participant added!',
        text: `A new participant added to space "${space?.name}".`
      });
      await refetchSpaceSettings();
      return true;
    }
    return false;
  };

  const onRemoveParticipant = async (email: string) => {
    if (!spaceId) {
      return false;
    }
    const response = await spaceAPI.removeSpaceParticipant(spaceId, email);
    if (response) {
      toaster.success({
        title: 'Participant removed!',
        text: `${email} removed from space "${space?.name}".`
      });
      await refetchSpaceSettings();
      return true;
    }
    return false;
  };

  const isSpaceOwner = useMemo(() => {
    return spaceSettings?.creator.email === user?.email;
  }, [spaceSettings, user]);

  return {
    spaceSettings,
    onAddParticipant,
    onRemoveParticipant,
    isSpaceOwner
  };
};

export default useSpaceSettings;
