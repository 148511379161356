import axios from 'axios';
import {
  IDataSchema,
  IProcessStatus,
  ISpace,
  ISpaceSettings
} from '@all-types/csat-types';
import { toaster } from '@ui/dialogs/ToastErrorMessage';

const apiUrl = process.env.REACT_APP_API_URL as string;

export default class SpaceAPI {
  private static instance: SpaceAPI;
  private host = apiUrl;

  private api: any;

  private constructor() {
    const token = localStorage.getItem('auth-token');
    this.api = axios.create({
      baseURL: this.host,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
  }

  public static getInstance(): SpaceAPI {
    if (!SpaceAPI.instance) {
      SpaceAPI.instance = new SpaceAPI();
    }

    return SpaceAPI.instance;
  }

  public async getSpaces(): Promise<ISpace[] | null> {
    const response = await this.api.get('/space/list').catch((error: any) => {
      toaster.error({ text: 'Cannot get spaces' });
      console.error(error.toJSON());
    });
    if ([200, 201].includes(response?.status)) {
      console.info('Spaces', response.data.items);
      return response.data.items;
    }
    return null;
  }

  public async getSpace(spaceId: string): Promise<ISpace | null> {
    const response = await this.api
      .get(`/space/${spaceId}`)
      .catch((error: any) => {
        toaster.error({ text: 'Cannot get space' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Space', response.data);
      return response.data;
    }
    return null;
  }

  public async deleteSpace(
    spaceId: string
  ): Promise<{ success: boolean } | null> {
    const response = await this.api
      .delete(`/space/${spaceId}`)
      .catch((error: any) => {
        toaster.error({ text: 'Cannot delete space' });
        console.error(error.toJSON());
        return null;
      });

    if ([200, 204].includes(response?.status)) {
      console.info('Space deleted', response.data);
      return response.data;
    }
    return null;
  }

  public async getSpaceProcessStatus(
    spaceId: string
  ): Promise<IProcessStatus | null> {
    const response = await this.api
      .get(`/space/${spaceId}/process-status`)
      .catch((error: any) => {
        toaster.error({ text: 'Cannot get space process status' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Space process status', response.data);
      return response.data;
    }
    return null;
  }

  public async setSpaceSchema(
    spaceId: string,
    schema: IDataSchema
  ): Promise<any | null> {
    const response = await this.api
      .post(`/space/${spaceId}/schema`, schema)
      .catch((error: any) => {
        toaster.error({ text: 'Cannot set space schema' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Set space schema response', response.data);
      return response.data;
    }
    return null;
  }

  public async createNewSpace(name: string): Promise<ISpace | null> {
    const response = await this.api
      .post('/space', { name })
      .catch((error: any) => {
        toaster.error({ text: 'Cannot create new space' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('New space created', response.data);
      return response.data;
    }
    return null;
  }

  public async getSpaceSettings(
    spaceId: string
  ): Promise<ISpaceSettings | null> {
    const response = await this.api
      .get(`/space-settings/${spaceId}`)
      .catch((error: any) => {
        toaster.error({ text: 'Cannot get space settings' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('Space settings', response.data);
      return response.data;
    }
    return null;
  }

  public async addSpaceParticipant(
    spaceId: string,
    email: string
  ): Promise<any | null> {
    const response = await this.api
      .post(`space-settings/${spaceId}/add-email`, { email })
      .catch((error: any) => {
        toaster.error({ text: 'Cannot add a new participant to the space' });
        console.error(error.toJSON());
      });
    if ([200, 201].includes(response?.status)) {
      console.info('A new participant was added to the space', response.data);
      return response.data;
    }
    return null;
  }

  public async removeSpaceParticipant(
    spaceId: string,
    email: string
  ): Promise<{ success: boolean } | null> {
    const response = await this.api
      .delete(`space-settings/${spaceId}/remove-email`, { data: { email } })
      .catch((error: any) => {
        toaster.error({ text: 'Cannot remove space participant' });
        console.error(error.toJSON());
        return null;
      });

    if ([200, 204].includes(response?.status)) {
      console.info('Space participant deleted', response.data);
      return response.data;
    }
    return null;
  }
}
