import React, { FC, useState } from 'react';
import { IoCalendarSharp } from 'react-icons/io5';
import { format } from 'date-fns';
import { cn } from '@/lib/utils';
import { Button, ButtonVariant } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';

interface IDatePickerProps {
  onChange: (data: Date | undefined) => void;
  defaultValue?: Date;
  value?: Date;
  disabled?: boolean;
}

const DatePicker: FC<IDatePickerProps> = ({
  onChange,
  defaultValue,
  disabled
}) => {
  const [date, setDate] = useState<Date | undefined>(defaultValue);

  const onDateChange = (newDate: Date | undefined) => {
    setDate(newDate);
    onChange(newDate);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={ButtonVariant.OUTLINE}
          className={cn(
            'w-[240px] justify-start text-left font-normal',
            !date && 'text-muted-foreground'
          )}
          disabled={disabled}>
          <IoCalendarSharp className="mr-2 h-4 w-4" />
          {date ? format(date, 'PPP') : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          selected={date}
          onSelect={onDateChange}
          initialFocus
          disabled={disabled}
        />
      </PopoverContent>
    </Popover>
  );
};

export default DatePicker;
