import { useContext, useMemo, useState } from 'react';
import { IFilters } from '@all-types/csat-types';
import { dateStringFromTimestamp } from '@utils/time';
import { SpaceContext, SpaceContextType } from '@context/contexts';
import useDateFilter from '@hooks/useDateFilter';

interface IAnalysisFiltersProps {
  filters: IFilters;
  applied: Partial<IFilters>;
  onSubmit: (data: Partial<IFilters>) => void;
}

const useAnalysisFilters = ({
  applied,
  filters,
  onSubmit
}: IAnalysisFiltersProps) => {
  const [selected, setSelected] = useState<Partial<IFilters>>(applied);

  const {
    categories: categoryFilters,
    sentiments: sentimentFilters,
    tags: tagFilters,
    tagsTypes: tagsTypeFilters
  } = filters;
  const { dataSchema } = useContext<SpaceContextType>(SpaceContext);
  const dateFilters = filters[dataSchema.date];
  const agentFilters = filters[dataSchema.agent];
  const teamFilters = filters[dataSchema.team];

  const onChangeFilters = (data: Partial<IFilters>) => {
    setSelected((prevState) => {
      const newData = { ...prevState, ...data };
      // return filters with values only
      return Object.keys(newData).reduce((acc, key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const value = newData[key];
        if (Array.isArray(value) && value.length === 0) {
          return acc;
        }
        if (!Array.isArray(value) && !value) {
          return acc;
        }
        return { ...acc, [key]: value };
      }, {});
    });
  };

  const { onDateChange, defaultDateValues, dateValues } = useDateFilter({
    onChangeFilters,
    selected,
    dateFilters
  });

  const onClearFilters = () => {
    setSelected({});
    onSubmit({});
  };

  const onFilterDelete = (key: string, value: string) => {
    setSelected((prevState) => {
      const newFilters: Partial<IFilters> = { ...prevState };

      if (key === 'date') {
        delete newFilters.startDate;
        delete newFilters.endDate;
      } else if (Array.isArray(newFilters[key])) {
        const index = (newFilters[key] as string[]).indexOf(value);
        if (index > -1) {
          (newFilters[key] as string[]).splice(index, 1);
        }
        if ((newFilters[key] as string[]).length === 0) {
          delete newFilters[key];
        }
      } else {
        delete newFilters[key];
      }

      return newFilters;
    });
  };

  const onSubmitFilters = () => {
    onSubmit(selected);
  };

  const filterValues = useMemo(() => {
    const selectedFilters = { ...selected };
    const dates = [];
    if (selectedFilters.startDate) {
      dates.push(selectedFilters.startDate);
      delete selectedFilters.startDate;
      if (selectedFilters.endDate) {
        dates.push(selectedFilters.endDate);
        delete selectedFilters.endDate;
      }
      selectedFilters.date = dates;
    }
    return Object.entries(selectedFilters).reduce(
      (acc: { key: string; value: string }[], [key, filterValue]) => {
        if (key.includes('date')) {
          return [
            ...acc,
            {
              key,
              value: `${dateStringFromTimestamp(filterValue[0])}${
                filterValue[1]
                  ? `-${dateStringFromTimestamp(filterValue[1])}`
                  : ''
              }`
            }
          ];
        }
        const values = Array.isArray(filterValue)
          ? filterValue.map((value) => ({ key, value }))
          : [{ key, value: filterValue }];
        return [...acc, ...values];
      },
      []
    );
  }, [selected]);

  const isFilterApplied = useMemo(() => {
    return Object.keys(applied).length > 0;
  }, [selected, applied]);

  const isFilterChanged = useMemo(() => {
    return JSON.stringify(selected) !== JSON.stringify(applied);
  }, [selected, applied]);

  return {
    selected,
    filterValues,
    isFilterApplied,
    isFilterChanged,
    onClearFilters,
    onFilterDelete,
    onChangeFilters,
    onDateChange,
    onSubmitFilters,
    defaultDateValues,
    dateValues,
    categoryFilters,
    sentimentFilters,
    tagFilters,
    tagsTypeFilters,
    agentFilters,
    teamFilters,
    dateFilters
  };
};

export default useAnalysisFilters;
