import React, { FC } from 'react';
import { cn } from '@/lib/utils';
import Spinner from '@ui/layout/Spinner';

interface IChartWrapperProps {
  children: React.ReactNode;
  className?: string;
  isLoading?: boolean;
}

const ChartWrapper: FC<IChartWrapperProps> = ({
  children,
  className,
  isLoading = false
}) => {
  return (
    <div
      className={cn('border bg-white border-gray-100 md:p-5 p-1', className)}>
      {isLoading ? <Spinner /> : <div>{children}</div>}
    </div>
  );
};

export default ChartWrapper;
