import React, { useMemo } from 'react';
import ChartWrapper from '@ui/charts/ChartWrapper';
import { getRoundNumber } from '@utils/numbers';

interface IScoreChartProps {
  title?: string;
  value?: number;
  wrapperClassName: string;
  isLoading?: boolean;
}

const ScoreChart: React.FC<IScoreChartProps> = ({
  title = 'Average Score',
  value = 0,
  wrapperClassName,
  isLoading
}) => {
  const roundedValue = useMemo(() => getRoundNumber(value), [value]);
  return (
    <ChartWrapper className={wrapperClassName} isLoading={isLoading}>
      <div className="flex flex-col justify-center items-center">
        <label className="text-lg font-bold text-gray-400 self-start">
          {title}
        </label>
        <div className="lg:pt-20 pt-6 flex justify-center items-center">
          <div className="text-9xl text-yellow-300">{roundedValue}</div>
        </div>
      </div>
    </ChartWrapper>
  );
};

export default ScoreChart;
