import React, { useMemo } from 'react';
import { IDataSchema, IFile, ISpaceCreator } from '@all-types/csat-types';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import Spinner from '@ui/layout/Spinner';
import { Button, ButtonVariant } from '@/components/ui/button';
import ConfirmationModal from '@ui/dialogs/ConfirmationModal';
import { CloseIcon } from '@icons';
import { Badge } from '@/components/ui/badge';
import InputDialog from '@ui/dialogs/InputDialog';

interface ISpaceInfoDialogProps {
  isSpaceOwner: boolean;
  title?: string;
  type?: string;
  dataSchema?: IDataSchema | null;
  files?: IFile[] | null;
  isLoading?: boolean;
  onDelete?: () => void;
  isOpen?: boolean;
  onOpenChange?: (value: boolean) => void;
  owner?: ISpaceCreator | null;
  participants?: string[] | null;
  onRemoveParticipant: (email: string) => void;
  onAddParticipant: (email: string) => void;
}

const SpaceInfoDialog = ({
  owner,
  participants,
  title = 'Default',
  type,
  dataSchema,
  files,
  isLoading = true,
  onDelete = () => {},
  isOpen = false,
  onOpenChange,
  onRemoveParticipant,
  onAddParticipant,
  isSpaceOwner
}: ISpaceInfoDialogProps) => {
  const deleteSpaceButton = useMemo(() => {
    const isDefault = title === 'Default';
    return (
      !isDefault &&
      isSpaceOwner && (
        <ConfirmationModal
          title={`Are you sure you want to delete this space "${title}"?`}
          onDelete={onDelete}
        />
      )
    );
  }, [title, isSpaceOwner, onDelete]);

  return (
    <div>
      <Dialog open={isOpen} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[525px]">
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
          <div className="grid py-4 max-h-96 overflow-scroll">
            {!isLoading ? (
              <div>
                {owner && (
                  <div className="pb-4">
                    <span className="font-bold">
                      Owner:&nbsp;
                      <span className="font-semibold text-gray-500">
                        {owner.email} (
                        <span className="text-darkCyan">{owner.name}</span>)
                      </span>
                    </span>
                  </div>
                )}
                <div className="pb-4">
                  <span className="font-bold">Participants:&nbsp;</span>

                  <div className="flex flex-wrap gap-1 ml-4 my-1 items-center">
                    {participants && participants.length > 0 ? (
                      <>
                        {participants.map((participant) => (
                          <Badge
                            key={participant}
                            variant="secondary"
                            className="hover:cursor-pointer h-[26px]"
                            onClick={() => {
                              onRemoveParticipant(participant);
                            }}>
                            {participant}
                            <CloseIcon className="block" aria-hidden="true" />
                          </Badge>
                        ))}
                      </>
                    ) : (
                      <span className="text-darkCyan">No participants</span>
                    )}
                    <InputDialog
                      title="Add new participant"
                      inputPlaceholder="Enter user email"
                      buttonTitle="Add"
                      onApply={onAddParticipant}
                      maxCharacters={30}
                      isEmail={true}>
                      <Button
                        variant={ButtonVariant.SECONDARY}
                        className="px-2 mx-2 !h-8 border text-darkCyan border-darkCyan cursor-pointer rounded-[0px]">
                        Add +
                      </Button>
                    </InputDialog>
                  </div>
                </div>
                <div className="pb-4">
                  <span className="font-bold">File schema:&nbsp;</span>
                  {dataSchema && Object.keys(dataSchema).length > 0 ? (
                    <div className="font-semibold text-gray-500 ml-4">
                      {dataSchema.fields.map((field) => field.title).join(', ')}
                    </div>
                  ) : (
                    <span className="text-main">
                      Space doesn&apos;t have schema yet.
                    </span>
                  )}
                </div>
                {(type === 'File' || !type) && (
                  <div className="pb-4">
                    <span className="font-bold">Files:&nbsp;</span>
                    {files && files.length > 0 ? (
                      <ul className="ml-4">
                        {files.map((file, index) => (
                          <li key={index}>&#8226;&nbsp;{file.fileName}</li>
                        ))}
                      </ul>
                    ) : (
                      <span className="text-main">No uploaded files</span>
                    )}
                  </div>
                )}

                {type && (
                  <div className="pb-4">
                    <span className="font-bold">
                      Space type:&nbsp;
                      <span className="font-medium">{type}</span>
                    </span>
                  </div>
                )}
                {deleteSpaceButton}
              </div>
            ) : (
              <Spinner />
            )}
          </div>
          <DialogFooter>
            <DialogClose>
              <Button>Close</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SpaceInfoDialog;
