import {
  FC,
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { cn } from '@/lib/utils';
import { useDropzone } from 'react-dropzone';
import { toast } from 'sonner';
import { MediaPreview } from '@ui/media/MediaPreview';
import { getSizeInMG } from '@utils/fileHelper';
import { types } from '@utils/FileConst';
import { CloseIcon, UploadIcon } from '@icons';

export enum FILE_TYPES {
  ALL = 'all',
  VIDEO = 'video',
  IMAGE = 'image',
  AUDIO = 'audio',
  DOCUMENT = 'document',
  EXCEL = 'excel'
}

type FileType = File & { preview: string };

interface IDropzone {
  className: string;
  onSelect: (file: File) => void;
  uploadMessage?: string;
  onDrop?: any;
  onDelete?: () => void;
  fileURL?: string | null;
  fileType?: string | null;
  maxSize?: number;
  recommendedSize?: [number, number];
  acceptedFileType?: string;
  onPreviewClick?: MouseEventHandler<HTMLImageElement>;
  showExternalImage?: boolean;
  disabled?: boolean;
  withPreview?: boolean;
}

export const Dropzone: FC<IDropzone> = ({
  className,
  onSelect,
  onDelete,
  onDrop,
  onPreviewClick,
  fileURL,
  fileType,
  maxSize = 1024 * 1024 * 240,
  uploadMessage = '',
  recommendedSize = [1280, 720],
  acceptedFileType = FILE_TYPES.ALL,
  showExternalImage,
  disabled = false,
  withPreview = true
}) => {
  const [file, setFile] = useState<FileType | null>(null);
  const [remoteFile, setRemoteFile] = useState<Partial<FileType> | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const resetInputRef = (): void => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const openDropzone = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  useEffect(() => {
    if (fileURL && fileType) {
      const fileData = {
        name: 'File Preview',
        preview: fileURL,
        type: fileType
      };
      setRemoteFile(fileData);
    }
  }, [fileURL, fileType]);

  useEffect(() => {
    if (file) {
      onSelect(file);
      if (file.type?.includes('video')) {
        const src = URL.createObjectURL(
          new Blob([file], { type: 'video/mp4' })
        );
        const fileData = {
          name: file.name,
          preview: src,
          type: file.type
        };
        setRemoteFile(fileData);
      }
    }
  }, [file]);

  const { getRootProps, getInputProps } = useDropzone({
    disabled,
    maxFiles: 1,
    maxSize,
    accept: types[acceptedFileType],
    onDrop: (acceptedFiles) => {
      onDrop?.(acceptedFiles);
      setFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )[0]
      );
      resetInputRef();
    },
    onDropRejected: (fileRejections) => {
      if (fileRejections[0].errors[0].code === 'file-too-large') {
        toast.error(`File is larger than ${getSizeInMG(maxSize)}`);
      }
      resetInputRef();
    },
    noClick: true,
    noKeyboard: true
  });

  const onClosePreview = (): void => {
    setFile(null);
    setRemoteFile(null);
    onDelete?.();
    resetInputRef();
  };

  const renderPreview = (fileData: Partial<FileType>): JSX.Element => {
    const isImage = fileData?.type?.includes('image');
    const width = isImage ? '225px' : '400px';
    const height = isImage ? '225px' : '100%';
    return (
      <div
        className={cn([
          'flex justify-center w-full h-96 border border-gray-200 rounded-[20px] relative',
          className
        ])}>
        <div className={`w-[${width}] h-[${height}] flex m-3 align-center`}>
          <MediaPreview
            src={fileData.preview as string}
            type={fileData.type as string}
            name={fileData.name as string}
            width={width}
            height={height}
            onClick={onPreviewClick}
          />
          {!disabled && (
            <button
              type="button"
              className="flex justify-center align-middle h-6.5 w-6.5 bg-gray-25 cursor-pointer opacity-50 hover:opacity-80 rounded-xl absolute top-0 right-0"
              onClick={onClosePreview}>
              <CloseIcon className="block h-6 w-6" aria-hidden="true" />
            </button>
          )}
        </div>
      </div>
    );
  };

  const fileData = useMemo(() => {
    if (showExternalImage) {
      return remoteFile;
    }
    return file || remoteFile;
  }, [showExternalImage, file, remoteFile]);

  const acceptedFilesText = useMemo((): string => {
    const fileTypes: { [key: string]: string } = {
      [FILE_TYPES.ALL]:
        'JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, PDF, TXT, DOC, PPT, XSL, XLSX',
      [FILE_TYPES.IMAGE]: 'JPG, PNG, GIF, SVG',
      [FILE_TYPES.VIDEO]: 'MP4, WEBM',
      [FILE_TYPES.AUDIO]: 'MP3, WAV',
      [FILE_TYPES.DOCUMENT]: 'PDF, TXT, DOC, PPT, CSV, XSL, XLSX',
      [FILE_TYPES.EXCEL]: 'CSV, XSL, XLSX'
    };
    return fileTypes[acceptedFileType];
  }, [acceptedFileType]);

  if (withPreview && fileData) {
    return renderPreview(fileData);
  }

  return (
    <div
      {...getRootProps({
        className: cn([
          'dropzone flex justify-center items-center w-full',
          className
        ])
      })}>
      <label
        htmlFor="dropzone-file"
        className="flex flex-col justify-center items-center w-full h-40 border border-gray-200 rounded-[20px] border-dashed cursor-pointer"
        onClick={openDropzone}>
        <div className="flex flex-col justify-center items-center">
          <UploadIcon />
          <p className="mt-2 mb-1 text-sm text-gray-300 font-medium">
            <span className="font-semibold text-sm text-darkCyan">
              Click to upload
            </span>{' '}
            or drag and drop
          </p>
          <p className="text-xs text-gray-300 font-medium text-center">
            {uploadMessage}
          </p>
          <br />
          <p className="text-xs text-gray-300 font-medium text-center">
            {`${acceptedFilesText}`}
            <br />
            <span className="block mt-2">
              Max file size {getSizeInMG(maxSize)}
            </span>
          </p>
        </div>
        <input
          ref={inputRef}
          id="dropzone-file"
          className="hidden"
          {...getInputProps()}
        />
      </label>
    </div>
  );
};
