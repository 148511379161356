import React, { FC, memo, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ChartWrapper from '@ui/charts/ChartWrapper';

type LegendOptions = {
  maxHeight?: number;
};
type YAxisOptions = {
  max?: number;
  tickInterval?: number;
};
interface IBarChartProps {
  data: any; // Array of values for each week in the selected month
  wrapperClassName?: string;
  title?: string;
  yAxisLabel?: string;
  xAxisLabel?: string;
  stacking?: string;
  categories?: string[] | number[];
  isLoading?: boolean;
  maxYAxisValue?: number;
  yAxisTickInterval?: number;
  maxLegendHeight?: number;
  legendOptions?: LegendOptions;
  yAxisOptions?: YAxisOptions;
}

const BarChart: FC<IBarChartProps> = memo(function BarChart({
  data,
  wrapperClassName,
  title = 'Bar Chart',
  yAxisLabel = 'Value',
  xAxisLabel = 'Value X',
  stacking,
  categories,
  isLoading,
  legendOptions = {},
  yAxisOptions = {}
}) {
  const options = useMemo(
    () => ({
      chart: {
        type: 'column'
      },
      title: {
        text: title,
        align: 'left',
        style: {
          color: 'rgb(107 114 128)'
        }
      },
      legend: {
        ...legendOptions
      },
      xAxis: {
        categories: categories || [xAxisLabel]
      },
      yAxis: {
        title: {
          text: yAxisLabel
        },
        ...yAxisOptions
      },
      series: data,
      plotOptions: {
        column: {
          stacking,
          dataLabels: {
            enabled: true
          }
        },
        series: {
          groupPadding: 0,
          pointPadding: 0.1,
          borderWidth: 0,
          borderRadius: 0,
          opacity: 0.9,
          colorByPoint: false,
          dataLabels: {
            enabled: true
          }
        }
      }
    }),
    [
      data,
      title,
      yAxisLabel,
      xAxisLabel,
      stacking,
      categories,
      legendOptions,
      yAxisOptions
    ]
  );

  return (
    <ChartWrapper className={wrapperClassName} isLoading={isLoading}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </ChartWrapper>
  );
});

export default BarChart;
