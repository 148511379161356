import { Option } from '@all-types/ui-types';

export const defaultFields: Option[] = [
  {
    value: 'remark',
    label: 'Remark (text)',
    required: true
  },
  {
    value: 'id',
    label: 'ID'
  },
  {
    value: 'agent',
    label: 'Agent'
  },
  {
    value: 'rating',
    label: 'Rating'
  },
  {
    value: 'team',
    label: 'Team'
  },
  {
    value: 'date',
    label: 'Date'
  },
  {
    value: 'link',
    label: 'Link'
  }
];
