import React, { FC, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { cn } from '@/lib/utils';
import { Button, ButtonVariant } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';

const FormSchema = z.object({
  items: z.array(z.string())
});

interface ICheckboxGroupProps {
  options: { id: string; label: string }[];
  onChange: (value: any) => void;
  onSubmit?: (data: z.infer<typeof FormSchema>) => void;
  className?: string;
  withSubmitButton?: boolean;
  defaultValues?: string[];
}

const CheckboxGroup: FC<ICheckboxGroupProps> = ({
  className,
  options,
  withSubmitButton = true,
  onSubmit = () => {},
  onChange,
  defaultValues = []
}) => {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      items: defaultValues
    },
    values: {
      items: defaultValues
    }
  });

  useEffect(() => {
    const subscription = form.watch((value) => onChange(value));
    return () => subscription.unsubscribe();
  }, [form.watch]);

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
          <div className={cn('max-h-[400px] overflow-auto', className)}>
            <FormField
              control={form.control}
              name="items"
              render={() => (
                <FormItem>
                  {options.map((item) => (
                    <FormField
                      key={item.id}
                      control={form.control}
                      name="items"
                      render={({ field }) => {
                        return (
                          <FormItem
                            key={item.id}
                            className="flex flex-row items-start space-x-3 space-y-0 hover:cursor-pointer hover:opacity-60 ">
                            <FormControl>
                              <Checkbox
                                checked={field.value?.includes(item.id)}
                                onCheckedChange={(checked) => {
                                  return checked
                                    ? field.onChange([...field.value, item.id])
                                    : field.onChange(
                                        field.value?.filter(
                                          (value) => value !== item.id
                                        )
                                      );
                                }}
                                className={cn({
                                  'bg-main border-none': field.value?.includes(
                                    item.id
                                  )
                                })}
                              />
                            </FormControl>
                            <FormLabel className="hover:cursor-pointer text-sm font-normal text-left">
                              {item.label}
                            </FormLabel>
                          </FormItem>
                        );
                      }}
                    />
                  ))}
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex justify-end">
            {withSubmitButton && (
              <Button
                variant={ButtonVariant.SECONDARY}
                className="font-bold"
                size="sm"
                type="submit">
                Apply
              </Button>
            )}
          </div>
        </form>
      </Form>
    </div>
  );
};

export default CheckboxGroup;
