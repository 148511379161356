import React, { FC } from 'react';

interface IMessageProps {
  icon: JSX.Element;
  message: string | string[] | JSX.Element;
  name: string;
}

const Message: FC<IMessageProps> = ({ name, icon, message }) => {
  const parseMessage = (sectionData: string | string[] | JSX.Element) => {
    return Array.isArray(sectionData) ? (
      <ul className="text-left">
        {sectionData.map((item: any, index: number) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    ) : (
      <div className="text-left">{sectionData}</div>
    );
  };

  return (
    <div className="flex flex-col text-gray-600 text-sm">
      <div className="flex flex-row justify-start items-center gap-2 p-1">
        {icon}
        <span className="block font-bold text-gray-700">{name}</span>
      </div>
      <p className="leading-relaxed text-left pl-12">{parseMessage(message)}</p>
    </div>
  );
};

export default Message;
