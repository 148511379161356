export const days = [
  { value: '7', label: 'Last 7 days' },
  { value: '14', label: 'Last 14 days' },
  { value: '30', label: 'Last 30 days' }
];
export const period = [
  { value: '1nd', label: 'Choose 1nd period' },
  { value: '2nd', label: 'Choose 2nd period' },
  { value: '3nd', label: 'Choose 3nd period' }
];
export const teams = [
  { value: '1', label: '1 person' },
  { value: '2', label: '2 person' },
  { value: 'All', label: 'All Teams' }
];
export const scripts = [
  { value: '1', label: '1 Scripts' },
  { value: '2', label: '2 Scripts' },
  { value: '3', label: '3 Scripts' },
  { value: '4', label: '4 Scripts' },
  { value: 'all', label: 'All Scripts 4/4' }
];
