import React, { FC, useMemo, memo } from 'react';
import ReactWordcloud, { Options } from 'react-wordcloud';
import ChartWrapper from '@ui/charts/ChartWrapper';
import { ITag } from '@all-types/csat-types';
import { WordColorByType } from '@const/defaultWorldColor';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

export type WordType = {
  text: string;
  value: number;
};

interface IWordCloudChartProps {
  wrapperClassName?: string;
  values?: ITag;
  isLoading?: boolean;
}

const WordCloudChart: FC<IWordCloudChartProps> = memo(function WordCloudChart({
  wrapperClassName,
  isLoading,
  values = {}
}) {
  const words = useMemo(() => {
    return Object.entries(values).map(([key, value]: any) => ({
      text: key,
      value: value.count,
      color: WordColorByType[value.sentiment]
    })) as WordType[];
  }, [values]);

  const options: Partial<Options> = useMemo(
    () => ({
      rotations: 1,
      scale: 'log',
      rotationAngles: [0, 0],
      fontSizes: [14, 100],
      padding: 2
    }),
    []
  );
  const callbacks = useMemo(
    () => ({
      // getWordColor: (val: any) => val.color,
      getWordTooltip: (word: WordType) => `${word.text} (${word.value})`
    }),
    []
  );
  return (
    <ChartWrapper className={wrapperClassName} isLoading={isLoading}>
      <ReactWordcloud
        maxWords={100}
        words={words}
        options={options}
        callbacks={callbacks}
      />
    </ChartWrapper>
  );
});

export default WordCloudChart;
