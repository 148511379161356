import * as React from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { Button, ButtonVariant } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table';
import { TableType } from '@utils/tableType';
import { Input } from '@/components/ui/input';
import ScriptDialogInfo from '@ui/dialogs/ScriptDialogInfo';
import { useState } from 'react';
import { Dots } from '@icons';

interface ITableInfoProps {
  data: any[];
  columns: ColumnDef<TableType>[];
  title?: string;
  withHeaderBar?: boolean;
  manualPagination?: boolean;
  rowCount?: number;
  pagination?: {
    pageIndex: number;
    pageSize: number;
  };
  setPagination: any;
}

export default function TableInfo({
  title,
  withHeaderBar = false,
  data,
  columns,
  manualPagination = false,
  rowCount = 0,
  pagination,
  setPagination
}: ITableInfoProps) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    manualPagination,
    rowCount: rowCount || data.length,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10
      }
    }
  });

  const renderPageButton = (pageIndex: number, isCurrent: boolean) => (
    <Button
      variant={isCurrent ? ButtonVariant.FILLED : ButtonVariant.OUTLINE}
      onClick={() => table.setPageIndex(pageIndex)}
      className="rounded-[100px] text-darkCyan border-none">
      {pageIndex + 1}
    </Button>
  );

  const renderPageRange = () => {
    const currentPage = table.getState().pagination.pageIndex;
    return Array.from({ length: table.getPageCount() }).map((_, index) => {
      const showPage =
        index === currentPage - 1 ||
        index === currentPage ||
        index === currentPage + 1;
      const isWithinMiddle = index > 0 && index < table.getPageCount() - 1;

      return showPage && isWithinMiddle
        ? renderPageButton(index, currentPage === index)
        : null;
    });
  };

  const renderPaginationControls = () => {
    const { pageIndex } = table.getState().pagination;

    return (
      <>
        {renderPageButton(0, pageIndex === 0)}
        {pageIndex > 2 && <Dots />}
        {renderPageRange()}
        {pageIndex < table.getPageCount() - 3 && <Dots />}
        {renderPageButton(
          table.getPageCount() - 1,
          pageIndex === table.getPageCount() - 1
        )}
      </>
    );
  };

  return (
    <div className="w-full bg-white border-2 border-gray-100">
      {withHeaderBar && (
        <div className="flex items-center py-4 px-2 justify-between">
          <div className="!h-8 text-base font-medium flex items-center">
            {title}
          </div>
          <div className="flex items-center">
            <Input
              placeholder="Input search text"
              value={
                ((table.getColumn('script')?.getFilterValue() as string) ||
                  (table.getColumn('team')?.getFilterValue() as string)) ??
                ''
              }
              onChange={(event) =>
                table.getColumn('script')?.setFilterValue(event.target.value) ||
                table.getColumn('team')?.setFilterValue(event.target.value)
              }
              className="w-[264px] !h-8 ml-4 justify-end"
            />
            <Button
              variant={ButtonVariant.OUTLINE}
              className="mr-2 ml-4 hover:text-lightBlue hover:border-lightBlue"
              size="sm">
              View Log
            </Button>
            <ScriptDialogInfo />
          </div>
        </div>
      )}
      <div className="rounded-md">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} className="border-left text-left">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="text-left">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex sm:flex-row flex-col items-center justify-end pr-4 sm:space-x-2 py-4">
        <div className="flex-1 sm:mb-0 mb-4 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{' '}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2 flex items-center">
          <Button
            variant={ButtonVariant.OUTLINE}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="rounded-[100px] text-darkCyan border-none">
            {'<'}
          </Button>
          {table.getPageCount() > 0 && renderPaginationControls()}
          <Button
            variant={ButtonVariant.OUTLINE}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="rounded-[100px] text-darkCyan border-none">
            {'>'}
          </Button>
          <select
            className="!h-8 ml-2 text-sm py-0 !w-[110px] border-darkCyan rounded-md"
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}>
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}/page
              </option>
            ))}
          </select>
          <span className="flex items-center gap-1 !h-8 ml-2">
            Go to
            <Input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="border p-1 rounded w-16 !h-8 text-sm ml-2 border-darkCyan rounded-md"
            />
          </span>
        </div>
      </div>
    </div>
  );
}
