import React, { FC } from 'react';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';

interface IInputFormProps {
  name: string;
  label?: string;
  placeholder?: string;
  type?: string;
  control: any;
  className?: string;
  description?: string;
}

const InputForm: FC<IInputFormProps> = ({
  name,
  label,
  placeholder,
  control,
  type,
  className,
  description
}) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {label && <FormLabel>{label}</FormLabel>}
          <FormControl>
            <Input
              className={cn('h-14 text-base', className)}
              placeholder={placeholder}
              type={type}
              {...field}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default InputForm;
