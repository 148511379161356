import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowUpDown } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';
import { TableType } from '@utils/tableType';
import { Checkbox } from '@/components/ui/checkbox';
import { Button, ButtonVariant } from '@/components/ui/button';
import {
  IScore,
  IScoreResponse,
  ISentimentByData
} from '@all-types/csat-types';
import { getRoundNumber } from '@utils/numbers';
import { SENTIMENT_COLORS } from '@utils/chartHelper';
import { Badge } from '@/components/ui/badge';

export const columnsTeam: ColumnDef<TableType>[] = [
  {
    accessorKey: 'remark',
    header: ({ table, column }) => {
      return (
        <div className="flex items-center">
          <Checkbox
            checked={table.getIsAllPageRowsSelected()}
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
          />
          <Button
            variant={ButtonVariant.GHOST}
            onClick={column.getToggleSortingHandler()}>
            Conversation Remark
            <ArrowUpDown className="h-4 w-4" />
          </Button>
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <div className="capitalize ml-4 flex flex-col gap-2">
          {row.original.remark}
          <span className="text-gray-300">{row.original.agentName}</span>
        </div>
      );
    }
  },

  {
    accessorKey: 'category',
    header: ({ column }) => {
      return (
        <div className="flex items-center">
          <Button
            variant={ButtonVariant.GHOST}
            onClick={column.getToggleSortingHandler()}>
            Category
            <ArrowUpDown className="h-4 w-4" />
          </Button>
        </div>
      );
    }
  },
  {
    accessorKey: 'sentiment',
    header: ({ column }) => {
      return (
        <div className="flex items-center">
          <Button
            variant={ButtonVariant.GHOST}
            onClick={column.getToggleSortingHandler()}>
            Sentiment
            <ArrowUpDown className="h-4 w-4" />
          </Button>
        </div>
      );
    }
  },
  {
    accessorKey: 'tags',
    header: ({ column }) => {
      return (
        <div className="flex items-center">
          <Button
            variant={ButtonVariant.GHOST}
            onClick={column.getToggleSortingHandler()}>
            Tags
            <ArrowUpDown className="h-4 w-4" />
          </Button>
        </div>
      );
    },
    cell: ({ row }) => {
      const { tags } = row.original;
      return (
        <div className="ml-4 flex flex-wrap gap-2">
          {tags?.length > 0 &&
            tags.map((tag: string) => (
              <Badge variant="secondary" key={tag}>
                {tag}
              </Badge>
            ))}
        </div>
      );
    }
  },
  {
    accessorKey: 'link',
    header: () => {
      return (
        <div className="flex items-center">
          <Button className="w-32" variant={ButtonVariant.GHOST}>
            Ticket link
          </Button>
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <Link
          to={row.original.link!}
          rel="noreferrer"
          className="bg-main text-white rounded-md px-4 py-2 mx-4"
          target="_blank">
          Link
        </Link>
      );
    }
  }
];

export const getChartData = (scoreData: IScore) => {
  return (
    scoreData &&
    Object.entries(scoreData)
      .filter(([key]) => key !== 'null')
      .map(([key, value]: any) => {
        return {
          name: key,
          data: [getRoundNumber(value.avg)]
        };
      })
  );
};

export const getTopicChartData = (data?: IScoreResponse | null) => {
  return (
    data &&
    Object.entries(data.distribution)
      .filter(([key]) => key !== 'null')
      .map(([key, value]: any) => {
        return {
          name: key,
          data: [value.value]
        };
      })
  );
};

export const getPieChartData = (scoreData: ISentimentByData) => {
  return (
    scoreData &&
    Object.entries(scoreData)
      .filter(([key]) => key !== 'null')
      .map(([key, value]: any) => {
        return {
          name: key,
          y: getRoundNumber(value.value),
          color: SENTIMENT_COLORS[key]
        };
      })
  );
};
