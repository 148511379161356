import React from 'react';

const BlinkingDots: React.FC = () => {
  return (
    <div className="flex gap-0.5">
      {[...Array(3)].map((_, index) => (
        <span
          key={index}
          className="flex w-1 h-1 bg-black rounded-[100px] animate-pulse transition-transform duration-1000 items-center"
        />
      ))}
    </div>
  );
};

export default BlinkingDots;
