import camelCase from 'lodash.camelcase';
import { IDataSchema } from '@all-types/csat-types';
import { Option } from '@all-types/ui-types';

export const roundToTwoDecimalPlaces = (num: number): number => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const getSizeInMG = (bytes = 0): string => {
  const size = roundToTwoDecimalPlaces(bytes / (1024 * 1024));
  return `${size}MB`;
};

export const getFileFromUrl = async (
  url: string,
  name: string
): Promise<File> => {
  return fetch(url)
    .then((r) => r.blob())
    .then((blobFile) => new File([blobFile], name, { type: 'image/png' }));
};

export const readFile = (file: File): Promise<any> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

export const getNFTName = (fileName: string, projectName: string): string => {
  return `${fileName} - ${projectName}`;
};

export const getFileName = (key: string): string => {
  return key.split('/')[1];
};

export const getLabelByFileType = (key: string): string => {
  const imageTypes = ['image/jpeg', 'image/svg+xml', 'image/png', 'image/gif'];
  const videoTypes = ['video/mp4', 'video/webm'];
  const documentTypes = [
    'application/pdf',
    'text/plain',
    'application/vnd.ms-powerpoint',
    'application/msword'
  ];
  if (imageTypes.includes(key)) {
    return 'Image';
  } else if (videoTypes.includes(key)) {
    return 'Video';
  } else if (documentTypes.includes(key)) {
    return 'Document';
  }
  return 'Unknown';
};

export const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
};

const stringKeys = ['agent', 'team', 'link'];
const numberKeys = ['id', 'rating'];
const dateKeys = ['date'];

// TODO Refactor this function
export const groupFileFields = (fields: {
  [key: string]: Option;
}): IDataSchema => {
  const groupedFields: IDataSchema = {
    textField: '',
    fields: []
  };
  // group values by typeof
  Object.entries(fields).forEach(([key, option]) => {
    if (key === 'remark') {
      groupedFields.textField = camelCase(fields[key].label);
      groupedFields.fields.push({
        type: 'String',
        key: camelCase(fields[key].label),
        title: option.label,
        special: key
      });
    } else if (numberKeys.includes(key)) {
      groupedFields.fields.push({
        type: 'Number',
        key: camelCase(option.label),
        title: option.label,
        special: key
      });
    } else if (dateKeys.includes(key)) {
      groupedFields.fields.push({
        type: 'Date',
        key: camelCase(option.label),
        title: option.label,
        special: key
      });
    } else if (stringKeys.includes(key)) {
      groupedFields.fields.push({
        type: 'String',
        key: camelCase(option.label),
        title: option.label,
        special: key
      });
    }
  });

  return groupedFields;
};
