import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import UserAPI from '@api/userAPI';

const useAuthentication = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { isLoading, error, user, getAccessTokenSilently } = useAuth0();

  const userAPI = useMemo(
    () => (isLoggedIn ? UserAPI.getInstance() : null),
    [isLoggedIn]
  );

  const syncUser = useCallback(async () => {
    if (userAPI) {
      const user = await userAPI.getUser();
      if (user) {
        setIsAuthenticated(true);
      }
    }
  }, [userAPI]);

  const getToken = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (token) {
      localStorage.setItem('auth-token', token);
      setIsLoggedIn(true);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (user) {
      getToken();
    }
  }, [user, getToken]);

  useEffect(() => {
    if (isLoggedIn) {
      syncUser();
    }
  }, [isLoggedIn, syncUser]);

  return { isAuthenticated, isLoading, authError: error };
};

export default useAuthentication;
