import React, { FC, useEffect } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  IFeedbacks,
  IFeedbackStatus,
  IFilters,
  IIntegrationStatus
} from '@all-types/csat-types';
import { Button, ButtonVariant } from '@/components/ui/button';
import InsightsTab from '@ui/csat-transcription/tabs/InsightsTab';
import { Back } from '@icons';
import useAnalyticsEventTracker from '@hooks/useAnalyticsEventTracker';
import AnalysisTab from '@ui/csat-transcription/tabs/AnalysisTab';
import FeedbacksTab from '@ui/csat-transcription/tabs/FeedbacksTab';
import useFeedbackAnalysis from '@hooks/useFeedbackAnalysis';
import useInsights from '@hooks/useInsights';
import useTableData from '@hooks/useTableData';
import StatusInfoButton from '@ui/status/StatusInfoButton';
import { IoMdSettings } from 'react-icons/io';
import { FaBell } from 'react-icons/fa';

interface ICsatTranscriptionProps {
  id: string;
  triggerRefetch: boolean;
  isRunning: boolean;
  isReady: boolean;
  isSuccessful: boolean;
  isFailed: boolean;
  appliedFilters?: Partial<IFilters>;
  feedbacks?: IFeedbacks | null;
  onBackClick?: () => void;
  toggleFilters?: () => void;
  feedbackStatus?: IFeedbackStatus;
  updateResultsCount: (count: number) => void;
  integrationStatus?: IIntegrationStatus['state'];
}

const CsatTranscription: FC<ICsatTranscriptionProps> = ({
  id,
  appliedFilters,
  onBackClick,
  toggleFilters,
  feedbackStatus,
  integrationStatus,
  isRunning,
  isReady,
  isFailed,
  isSuccessful,
  triggerRefetch,
  updateResultsCount
}) => {
  const isInsightsDisabled = !isReady || isRunning;
  const gaEventTracker = useAnalyticsEventTracker('Analysis Page');

  const {
    avgData,
    isAvgRatingLoading,
    avgScoreByCategory,
    isAvgScoreByCategoryLoading,
    avgScoreByAgent,
    isAvgScoreByAgentLoading,
    avgScoreByTeam,
    isAvgScoreByTeamLoading,
    filledRatio,
    isFilledRatioLoading,
    categories,
    isTopicsLoading,
    sentiment,
    isSentimentLoading,
    sentimentByRating,
    isSentimentByRatingLoading,
    sentimentByTopic,
    isSentimentByTopicLoading,
    tags,
    isTagsLoading,
    isRatingEnabled,
    isAgentEnabled,
    isTeamEnabled
  } = useFeedbackAnalysis({ id, appliedFilters, triggerRefetch });
  const { tableData, columns, rowCount, pagination, setPagination } =
    useTableData({ appliedFilters, triggerRefetch });
  const {
    messages,
    insights,
    isInsightsLoading,
    refetchInsights,
    sendMessage
  } = useInsights({
    id,
    appliedFilters
  });

  useEffect(() => {
    updateResultsCount(rowCount);
  }, [rowCount]);

  return (
    <div className="w-full">
      <Tabs defaultValue="analysis" className="sm:w-full w-screen sm:px-0 px-5">
        <div className="relative">
          <Button
            className="sm:absolute sm:-left-24 md:left-0 left-0 mb-5 sm:mb-0 pl-4 md:h-10 h-8 rounded-lg text-darkCyan"
            onClick={onBackClick}
            variant={ButtonVariant.SECONDARY}>
            <Back />
            <span>Back</span>
          </Button>
          <Button
            className="mb-5 sm:mb-0 h-8 rounded-lg sm:hidden absolute right-2 top-0"
            variant={ButtonVariant.SECONDARY}
            onClick={toggleFilters}>
            Show filters
          </Button>
          <TabsList className="mb-3 sm:w-max self-center">
            <TabsTrigger value="analysis" className="md:text-sm text-[12px]">
              Analysis
            </TabsTrigger>
            <TabsTrigger
              value="feedbacks"
              className="md:text-sm text-[12px]"
              onClick={() =>
                gaEventTracker('feedbacks_view', 'Open Feedback tab')
              }>
              Feedbacks
            </TabsTrigger>
            <TabsTrigger
              value="summary"
              className="md:text-sm text-[12px]"
              onClick={() =>
                gaEventTracker('insights_view', 'Open Insights tab')
              }>
              Insights & Recommendations
            </TabsTrigger>
          </TabsList>
          <div className="absolute top-0 flex gap-4 right-28">
            <Button
              variant={ButtonVariant.GHOST}
              disabled={true}
              className="text-darkCyan bg-lightCyan font-semibold">
              <FaBell />
              <span>Notify Me</span>
            </Button>
            <Button
              disabled={true}
              variant={ButtonVariant.SECONDARY}
              className="text-darkCyan !bg-white font-semibold !border-darkCyan !border-1">
              <IoMdSettings />
              <span>Customize</span>
            </Button>
          </div>
          <div className="absolute right-0 top-1 ">
            <StatusInfoButton
              isRunning={isRunning}
              isFailed={isFailed}
              isSuccessful={isSuccessful}
              integrationStatus={integrationStatus}
              feedbackStatus={feedbackStatus}
            />
          </div>
        </div>
        <TabsContent value="analysis">
          <AnalysisTab
            avgData={avgData}
            isAvgRatingLoading={isAvgRatingLoading}
            avgScoreByCategory={avgScoreByCategory}
            isAvgScoreByCategoryLoading={isAvgScoreByCategoryLoading}
            avgScoreByAgent={avgScoreByAgent}
            isAvgScoreByAgentLoading={isAvgScoreByAgentLoading}
            avgScoreByTeam={avgScoreByTeam}
            isAvgScoreByTeamLoading={isAvgScoreByTeamLoading}
            filledRatio={filledRatio}
            categories={categories}
            sentiment={sentiment}
            sentimentByRating={sentimentByRating}
            sentimentByTopic={sentimentByTopic}
            tags={tags}
            isRatingEnabled={isRatingEnabled}
            isAgentEnabled={isAgentEnabled}
            isTeamEnabled={isTeamEnabled}
            isTagsLoading={isTagsLoading}
            isFilledRatioLoading={isFilledRatioLoading}
            isTopicsLoading={isTopicsLoading}
            isSentimentLoading={isSentimentLoading}
            isSentimentByRatingLoading={isSentimentByRatingLoading}
            isSentimentByTopicLoading={isSentimentByTopicLoading}
          />
        </TabsContent>
        <TabsContent value="feedbacks">
          <FeedbacksTab
            tableData={tableData}
            rowCount={rowCount}
            columns={columns}
            pagination={pagination}
            setPagination={setPagination}
          />
        </TabsContent>
        <TabsContent value="summary">
          <InsightsTab
            isInsightsDisabled={isInsightsDisabled}
            messages={messages}
            insights={insights}
            isInsightsLoading={isInsightsLoading}
            refetchInsights={refetchInsights}
            sendMessage={sendMessage}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default CsatTranscription;
