import React, { FC, useEffect, useRef, useState } from 'react';
import { GiArtificialHive } from 'react-icons/gi';
import { FaRegUserCircle } from 'react-icons/fa';
import { BsSendFill } from 'react-icons/bs';
import { Input } from '@/components/ui/input';
import { Button, ButtonVariant } from '@/components/ui/button';
import Message from '@ui/chat/Message';
import Spinner from '@ui/layout/Spinner';
import { MessageType } from '@hooks/useInsights';

interface IChatWrapperProps {
  onSendMessage: () => Promise<void>;
  onChange: (val: string) => void;
  messages: MessageType[];
  isLoading?: boolean;
  isDisabled?: boolean;
}

const ChatWrapper: FC<IChatWrapperProps> = ({
  onSendMessage,
  onChange,
  messages,
  isLoading,
  isDisabled = false
}) => {
  const chatRef = useRef<HTMLDivElement>(null);
  const [message, setMessage] = useState<string>('');

  const onMessageChange = (value: any) => {
    onChange(value);
    setMessage(value);
  };

  const sentMessageHandler = async () => {
    if (message) {
      setMessage('');
      await onSendMessage();
    }
  };

  useEffect(() => {
    // scroll to bottom of chat wrapper
    chatRef.current?.scrollTo(0, chatRef.current.scrollHeight);
  }, [messages]);

  return (
    <div className="h-full relative flex flex-col">
      <div
        className="flex flex-col gap-4 pr-4 w-full h-[calc(100%-5rem)] overflow-y-scroll"
        ref={chatRef}>
        {messages.map((message) => (
          <Message
            key={message.sentTime}
            icon={
              message.sender === 'AI' ? (
                <GiArtificialHive size="35px" className="text-darkCyan" />
              ) : (
                <FaRegUserCircle size="35px" className="text-darkCyan" />
              )
            }
            name={message.sender}
            message={message.message}
          />
        ))}
      </div>
      <div className="flex items-center pt-0 flex-row gap-4">
        <div>{isLoading && <Spinner className="" />}</div>
        <Input
          disabled={isDisabled}
          value={message}
          placeholder="Send a message"
          onChange={(e) => onMessageChange(e.target.value)}
          onKeyUp={async (e) => {
            if (e.key === 'Enter') {
              await sentMessageHandler();
            }
          }}
        />
        <div className="pt-4 flex justify-end pb-4">
          <Button
            disabled={isDisabled}
            variant={ButtonVariant.DEFAULT}
            onClick={sentMessageHandler}>
            <BsSendFill />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChatWrapper;
