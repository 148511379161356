import React, { FC, useContext } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@/components/ui/accordion';
import { IFilters } from '@all-types/csat-types';
import CheckboxGroup from '@ui/forms/CheckboxGroup';
import DatePickerWithRange from '@ui/forms/DatePickerWithRange';
import { Button, ButtonVariant } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { CloseIcon } from '@icons';
import FilterSection from '@ui/filters/FilterSection';
import useAnalysisFilters from '@hooks/useAnalysisFilters';
import { SpaceContext, SpaceContextType } from '@context/contexts';

interface IAnalysisFiltersProps {
  filters: IFilters;
  applied: Partial<IFilters>;
  onSubmit: (data: Partial<IFilters>) => void;
  resultsCount: number;
}

const mapToOptions = (items: string[]) => {
  return items.map((item) => ({ id: item, label: item }));
};

const DEFAULT_ACCORDION_VALUES = [
  'date',
  'categories',
  'sentiments',
  'tags',
  'tagsTypes',
  'agents',
  'teams'
];

const AnalysisFilters: FC<IAnalysisFiltersProps> = ({
  filters,
  applied,
  onSubmit,
  resultsCount
}) => {
  const { dataSchema } = useContext<SpaceContextType>(SpaceContext);
  const {
    selected,
    filterValues,
    isFilterApplied,
    isFilterChanged,
    onClearFilters,
    onFilterDelete,
    onChangeFilters,
    onDateChange,
    defaultDateValues,
    dateValues,
    categoryFilters,
    sentimentFilters,
    tagFilters,
    tagsTypeFilters,
    agentFilters,
    teamFilters,
    dateFilters,
    onSubmitFilters
  } = useAnalysisFilters({ applied, filters, onSubmit });

  return (
    <div className="w-full max-h-[calc(100vh-124px)] bg-white px-4 pt-1 border border-gray-100 sm:overflow-auto">
      <div className="border-b border-b-gray-100">
        <span className="flex py-4 bg-white border-b border-b-gray-100 text-gray-400">
          {resultsCount} results
        </span>
        {!!filterValues.length && (
          <div className="flex flex-col justify-between items-start">
            <div className="flex flex-wrap gap-2 py-4 max-h-[200px] overflow-auto">
              {filterValues.map((item) => (
                <Badge
                  key={item.value}
                  variant="lightCyan"
                  className="hover:cursor-pointer"
                  onClick={() => {
                    onFilterDelete(item.key, item.value);
                  }}>
                  <span className="pr-1">{item.value}</span>
                  <CloseIcon className="block" aria-hidden="true" />
                </Badge>
              ))}
            </div>
            <Button
              variant={ButtonVariant.LINK}
              size="sm"
              onClick={onClearFilters}
              className="text-darkCyan">
              Clear All
            </Button>
          </div>
        )}
      </div>
      <Accordion
        type="multiple"
        className="w-full"
        defaultValue={DEFAULT_ACCORDION_VALUES}>
        {dateFilters && (
          <AccordionItem value="date">
            <AccordionTrigger className="text-darkCyan font-medium">
              Time Period
            </AccordionTrigger>
            <AccordionContent className="flex flex-col gap-4">
              <DatePickerWithRange
                defaultValue={defaultDateValues}
                value={dateValues}
                onChange={onDateChange}
              />
            </AccordionContent>
          </AccordionItem>
        )}
        {categoryFilters && (
          <AccordionItem value="categories">
            <AccordionTrigger className="text-darkCyan font-medium">
              Categories
            </AccordionTrigger>
            <AccordionContent>
              <CheckboxGroup
                options={mapToOptions(categoryFilters)}
                onChange={({ items }) => onChangeFilters({ categories: items })}
                defaultValues={selected.categories}
                withSubmitButton={false}
              />
            </AccordionContent>
          </AccordionItem>
        )}
        {sentimentFilters && (
          <AccordionItem value="sentiments">
            <AccordionTrigger className="text-darkCyan font-medium">
              Sentiments
            </AccordionTrigger>
            <AccordionContent>
              <CheckboxGroup
                options={mapToOptions(sentimentFilters)}
                onChange={({ items }) => onChangeFilters({ sentiments: items })}
                defaultValues={selected.sentiments}
                withSubmitButton={false}
              />
            </AccordionContent>
          </AccordionItem>
        )}
        {agentFilters && (
          <FilterSection
            title="Agents"
            value="agents"
            values={agentFilters}
            defaultValues={selected[dataSchema.agent]}
            onChangeFilters={(items) =>
              onChangeFilters({ [dataSchema.agent]: items })
            }
            withSearch
          />
        )}
        {teamFilters && (
          <FilterSection
            title="Teams"
            value="teams"
            values={teamFilters}
            defaultValues={selected[dataSchema.team]}
            onChangeFilters={(items) =>
              onChangeFilters({ [dataSchema.team]: items })
            }
            withSearch
          />
        )}
        {tagFilters && (
          <FilterSection
            title="Tags"
            value="tags"
            values={tagFilters}
            defaultValues={selected.tags}
            onChangeFilters={(items) => onChangeFilters({ tags: items })}
            withSearch
          />
        )}
        {tagsTypeFilters && (
          <AccordionItem value="tagsTypes">
            <AccordionTrigger className="text-darkCyan font-medium">
              Tags Types
            </AccordionTrigger>
            <AccordionContent>
              <CheckboxGroup
                options={mapToOptions(tagsTypeFilters)}
                onChange={({ items }) => onChangeFilters({ tagsTypes: items })}
                defaultValues={selected.tagsTypes}
                withSubmitButton={false}
              />
            </AccordionContent>
          </AccordionItem>
        )}
      </Accordion>
      <div className="flex justify-between sticky bottom-0 py-4 bg-white border-t border-t-gray-100">
        <Button
          variant={ButtonVariant.SECONDARY}
          onClick={onClearFilters}
          disabled={!isFilterApplied}
          className="text-darkCyan">
          Clear All
        </Button>
        <Button onClick={onSubmitFilters} disabled={!isFilterChanged}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default AnalysisFilters;
