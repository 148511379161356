import React from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/components/ui/alert-dialog';
import { BiSolidError } from 'react-icons/bi';
import { Button } from '@/components/ui/button';

interface IAlertDialogMessageProps {
  description?: string | JSX.Element;
  isOpen?: boolean;
  onClick?: () => void;
  buttonTitle?: string;
  title?: string;
}

const AlertDialogMessage = ({
  description,
  isOpen,
  onClick,
  buttonTitle = 'OK',
  title
}: IAlertDialogMessageProps) => {
  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-darkCyan text-md">
            {title}
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription className="flex text-md">
          <div>
            <BiSolidError size="24px" className="text-red-500" />
          </div>
          <span className="pl-2">{description}</span>
        </AlertDialogDescription>
        <AlertDialogFooter>
          <Button className="px-6" onClick={onClick}>
            {buttonTitle}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AlertDialogMessage;
