import { Intercom, Medallia, Servicecloud, Zendesk } from '@icons';
import React from 'react';

export type ServiceType = {
  name: string;
  icon: JSX.Element;
  smallIcon: JSX.Element;
  disabled: boolean;
};

export const services: ServiceType[] = [
  {
    name: 'Intercom',
    icon: <Intercom />,
    smallIcon: <Intercom style={{ width: 48 }} />,
    disabled: false
  },
  {
    name: 'Zendesk',
    icon: <Zendesk />,
    smallIcon: <Zendesk style={{ width: 48 }} />,
    disabled: true
  },
  {
    name: 'Service Cloud',
    icon: <Servicecloud />,
    smallIcon: <Servicecloud style={{ width: 48 }} />,
    disabled: true
  },
  {
    name: 'Medallia',
    icon: <Medallia />,
    smallIcon: <Medallia style={{ width: 48 }} />,
    disabled: true
  }
];
