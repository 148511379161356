import React, { FC } from 'react';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from '@/components/ui/carousel';
import TooltipWrapper from '@ui/tooltip/TooltipWrapper';
import { Card, CardContent } from '@/components/ui/card';
import { Link } from 'react-router-dom';
import { BsFiletypeXlsx } from 'react-icons/bs';

interface IRecentlyUploadedProps {
  files: any;
}

const RecentlyUploaded: FC<IRecentlyUploadedProps> = ({ files }) => {
  return (
    <div className="py-4">
      <span className="flex justify-center text-2xl pb-6">
        Recently uploaded
      </span>
      <div className="flex justify-center">
        <Carousel opts={{ align: 'start' }} className="w-full">
          <CarouselContent>
            {[...files].reverse().map((file, index) => (
              <CarouselItem
                key={index}
                className="basis-1/2 sm:basis-1/3 md:basis-1/5 hover:opacity-50 hover:cursor-pointer overflow-hidden">
                <TooltipWrapper text={file.fileName}>
                  <div className="p-1">
                    <Card>
                      <Link to={`/csat/${file.id}`}>
                        <CardContent className="flex aspect-square flex-col p-0 justify-center items-center">
                          <BsFiletypeXlsx className="w-1/3 h-1/3 text-darkCyan" />
                        </CardContent>
                      </Link>
                    </Card>
                    <span className="text-sm font-semibold text-center">
                      {file.fileName}
                    </span>
                  </div>
                </TooltipWrapper>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious className="sm:flex hidden" />
          <CarouselNext className="sm:flex hidden" />
        </Carousel>
      </div>
    </div>
  );
};

export default RecentlyUploaded;
