import React, { FC, useEffect, useMemo, useState } from 'react';
import { read, utils } from 'xlsx';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Button, ButtonVariant } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import MapsFieldsCombobox from '@ui/combobox/MapsFieldsCombobox';
import { FileType } from '@ui/media/FileDropzone';
import { Option } from '@all-types/ui-types';
import { defaultFields } from '@const/defaultFileFields';

interface IFileFieldsDialogProps {
  onApply: (value: { [key: string]: Option }) => void;
  file: FileType | null;
}

const requiredFields = defaultFields.filter((field) => field.required);

const FileFieldsDialog: FC<IFileFieldsDialogProps> = ({ onApply, file }) => {
  const [fileColumns, setFileColumns] = useState<Option[]>([]);
  const [previewRows, setPreviewRows] = useState<any[]>([]);
  const [previewField, setPreviewField] = useState<string>('');
  const [previewData, setPreviewData] = useState<string[]>([]);
  const [selectedFields, setSelectedFields] = useState<{
    [key: string]: Option;
  }>({});
  const [initialSelectedFields, setInitialSelectedFields] = useState<{
    [key: string]: Option;
  }>({});

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        const workbook = read(data, { type: 'binary' });
        const firstSheet = workbook.SheetNames[0];
        const excelRows = utils.sheet_to_json(workbook.Sheets[firstSheet]);
        const columns = utils.sheet_to_json(workbook.Sheets[firstSheet], {
          header: 1,
          range: 0
        })[0] as string[];
        setPreviewRows(excelRows.slice(0, 10));
        const fields = columns.map((field) => ({
          value: field.split(' ').join('_').toLowerCase(),
          label: field
        }));
        setFileColumns(fields);
      };
      reader.readAsBinaryString(file);
    }
  }, [file]);

  const onChangePreviewField = (field: string) => {
    if (field) {
      setPreviewField(field);
      const dataForPreview = previewRows
        .map((row: any) => row[field])
        .filter((value: any) => value);
      setPreviewData(dataForPreview);
    }
  };

  const onChange = (field: string, option: Option | null) => {
    setSelectedFields((prevState) => {
      const newState = { ...prevState };
      if (option) {
        newState[field] = option;
      } else {
        delete newState[field];
      }
      return newState;
    });
  };

  const onApplyFields = () => {
    onApply(selectedFields);
    setInitialSelectedFields(selectedFields);
  };

  const onCancel = () => {
    setSelectedFields(initialSelectedFields);
  };

  const isApplyDisabled = useMemo(() => {
    return !requiredFields.every((field) => selectedFields[field.value]);
  }, [selectedFields]);

  return (
    <Dialog onOpenChange={(open) => !open && onCancel()}>
      <DialogTrigger disabled={!file}>
        <Button
          variant={ButtonVariant.OUTLINE}
          className="border"
          size="sm"
          disabled={!file}>
          Open fields modal
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[1250px] bg-white h-[700px]">
        <DialogHeader className="border-b-2 border-b-gray-100 h-10">
          <DialogTitle>Map Fields</DialogTitle>
        </DialogHeader>
        <div className="w-full flex h-full">
          <div className="w-full h-full">
            <span className="flex w-full pb-10">
              Review and confirm each mapping choice
            </span>
            <div className="flex flex-row w-full">
              <div className="w-3/5">
                <div className="flex justify-between w-full gap-4">
                  <div className="w-1/3">
                    <div className="flex justify-start gap-4 items-center">
                      <span className="text-xs">DESTINATION FIELDS</span>
                      <Badge variant="chichi" className="text-xs">
                        1 of {defaultFields.length}
                      </Badge>
                    </div>
                  </div>
                  <div className="w-2/3">
                    <div className="flex justify-start gap-4 items-center">
                      <span className="text-xs">INCOMING FIELDS</span>
                      <Badge variant="chichi" className="text-xs">
                        {fileColumns.length} Columns
                      </Badge>
                    </div>
                  </div>
                </div>
                <ul>
                  {defaultFields.map((field, index) => (
                    <li
                      key={index}
                      className="flex justify-between py-2 items-center"
                      onMouseOver={() =>
                        onChangePreviewField(selectedFields[field.value]?.label)
                      }>
                      <div className="w-1/3 flex flex-row gap-2 items-start">
                        <Badge variant="lightCyan">
                          <span>{field.label}</span>
                        </Badge>
                        {field.required && (
                          <span className="text-red-500 text-2xl">*</span>
                        )}
                      </div>
                      <div className="w-2/3">
                        <MapsFieldsCombobox
                          options={fileColumns}
                          selected={selectedFields[field.value]?.value}
                          onChange={(option) => onChange(field.value, option)}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
                <Button disabled={true} className="mt-2">
                  Add more data
                </Button>
              </div>
              <div className="w-2/5 p-6 mt-[-24px]">
                <div className="flex justify-start gap-4 items-center mb-2">
                  <span className="text-xs">DATA PREVIEW FOR</span>
                  {previewField && (
                    <Badge variant="chichi" className="text-xs">
                      {previewField}
                    </Badge>
                  )}
                </div>
                <div className="p-4 border rounded-md overflow-y-scroll h-[400px] box-border">
                  {previewData.map((value, index) => (
                    <div
                      key={`${value}-${index}`}
                      className="overflow-y-scroll">
                      <h1 className="text-[12px]">{value}</h1>
                      {index < 9 && <hr className="my-2" />}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogFooter className="border-t-2 border-t-gray-100 pt-4">
          <DialogClose asChild>
            <div className="flex flex-row gap-4">
              <Button
                variant={ButtonVariant.OUTLINE}
                className="border"
                size="sm">
                Cancel
              </Button>
              <Button
                onClick={onApplyFields}
                className="border"
                size="sm"
                disabled={isApplyDisabled}>
                Apply
              </Button>
            </div>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default FileFieldsDialog;
