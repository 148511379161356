import React from 'react';
import { Button, ButtonVariant } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { Textarea } from '@/components/ui/textarea';

interface IAddScriptDialogProps {
  trigger?: JSX.Element;
}
export default function AddScriptDialog({ trigger }: IAddScriptDialogProps) {
  const onFileUpload = (): void => {
    console.log('file');
  };

  const onFileDelete = (): void => {
    console.log('delete');
  };

  return (
    <Dialog>
      {trigger}
      <DialogContent className="sm:max-w-[963px]">
        <DialogHeader>
          <DialogTitle>New Script</DialogTitle>
        </DialogHeader>
        <hr className="w-full" />
        <div className="py-4 flex ">
          <div className="sm:max-w-[395px] mr-12">
            <div className="pb-6">
              <Textarea
                className="!w-[395px] !h-[318px]"
                placeholder="Add each question and possible choice on its own line. Press Enter on your keyboard twice to separate each question. __________________________________________________________ 
For Example:
What is your favorite color?
true false 
What is your favorite shape?
true false true 50%"
              />
            </div>
            <div className="h-[172px] border text-center flex justify-center items-center text-base font-medium">
              Add Audio
            </div>
          </div>
          <div className="sm:max-w-[395px] ml-6">
            <div className="mb-3 text-base font-medium">Preview</div>
            <div className="w-[448px] h-[282px] flex justify-center items-center text-base font-medium">
              No questions added
            </div>
          </div>
        </div>
        <hr className="w-full" />
        <DialogFooter>
          <DialogClose>
            <Button
              variant={ButtonVariant.OUTLINE}
              className="border"
              size="sm">
              Cancel
            </Button>
          </DialogClose>
          <Button type="submit" className="text-white bg-main" size="sm">
            Ok
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
