import { useCallback, useState } from 'react';
import { toaster } from '@ui/dialogs/ToastErrorMessage';
import { IDataSchema, IFileSchema } from '@all-types/csat-types';
import { FileType } from '@ui/media/FileDropzone';
import FileAPI from '@api/fileAPI';
import SpaceAPI from '@api/spaceAPI';

interface IFeedbackFileUploadProps {
  currentSpaceId: string;
  dataSchema: IFileSchema;
  onSuccess: (fileId: string) => void;
  refetchCurrentSpace: () => Promise<any>;
}

// TODO Refactor this hook later
const useFeedbackFileUpload = ({
  currentSpaceId,
  dataSchema,
  onSuccess,
  refetchCurrentSpace
}: IFeedbackFileUploadProps) => {
  const fileAPI = FileAPI.getInstance();
  const spaceAPI = SpaceAPI.getInstance();

  const [isFileProcessing, setIsFileProcessing] = useState(false);
  const [fileIsValid, setFileIsValid] = useState<boolean>(false);
  const [s3FileName, setS3FileName] = useState<string>('');
  const [showColumnsAlert, setShowColumnsAlert] = useState(false);
  const [previewErrorMessage, setPreviewErrorMessage] = useState<string>('');

  const cancelUpload = (): void => {
    setIsFileProcessing(false);
  };

  const closeColumnsAlert = (): void => {
    setShowColumnsAlert(false);
  };

  const checkIfFileIsProcessing = async (
    spaceId: string,
    fileId: string
  ): Promise<void> => {
    const intervalId = setInterval(async () => {
      const statusResponse = await spaceAPI.getSpaceProcessStatus(spaceId);
      const status = statusResponse?.feedbackStatuses;
      if (
        status &&
        ((typeof status.Pending === 'number' && status.Pending > 0) ||
          (typeof status.InProgress === 'number' && status.InProgress > 0))
      ) {
        clearInterval(intervalId);
        setIsFileProcessing(false);
        toaster.success({
          text: 'Starting processing.',
          title: 'File successfully uploaded!'
        });
        onSuccess(fileId);
      }
      if (status?.Failed) {
        clearInterval(intervalId);
        cancelUpload();
        toaster.error(
          {
            text: 'Some issue occurred while processing the file.',
            title: 'Failure!'
          },
          {
            duration: Infinity
          }
        );
      }
    }, 3000);
  };

  const setSpaceSchema = async (
    spaceId: string,
    fields: IDataSchema
  ): Promise<any | null> => {
    await spaceAPI.setSpaceSchema(spaceId, fields);
    await refetchCurrentSpace();
  };

  const uploadFileAndValidate = useCallback(
    async (file: File): Promise<boolean> => {
      const fileExt = file?.name.split('.').pop() || 'csv';
      const uploadData = await fileAPI.getUploadURL(
        currentSpaceId as string,
        fileExt
      );

      if (!uploadData) {
        cancelUpload();
        return false;
      }

      const uploadedToS3 = await fileAPI.uploadFile(uploadData.url, file);

      if (!uploadedToS3) {
        cancelUpload();
        return false;
      }

      const status = await fileAPI.validateFileStructure(
        currentSpaceId as string,
        uploadData.s3FileName
      );

      //if status is a string - it means we got error message
      if (typeof status === 'string') {
        setPreviewErrorMessage(status);
        setShowColumnsAlert(true);
        cancelUpload();
        return false;
      }

      setFileIsValid(true);
      setS3FileName(uploadData.s3FileName);
      return true;
    },
    [currentSpaceId]
  );

  const processFile = useCallback(
    async (file: FileType, spaceId: string, s3FileName: string) => {
      const fileName = file?.name.split('.').shift() || 'test-file';
      const fileResponse = await fileAPI.addFileForProcessing(
        spaceId,
        s3FileName,
        fileName
      );
      if (fileResponse) {
        await checkIfFileIsProcessing(spaceId, fileResponse.fileId);
      } else {
        cancelUpload();
      }
    },
    []
  );

  const onUploadFile = useCallback(
    async (file: FileType, fields: IDataSchema | null): Promise<void> => {
      setIsFileProcessing(true);
      const isFileSchemaEmpty = Object.keys(dataSchema || {}).length === 0;
      const schema = isFileSchemaEmpty && fields ? fields : null;

      if (schema) {
        await setSpaceSchema(currentSpaceId, schema);
      }
      await processFile(file, currentSpaceId, s3FileName);
    },
    [dataSchema, currentSpaceId, s3FileName]
  );

  return {
    onUploadFile,
    cancelUpload,
    fileIsValid,
    isFileProcessing,
    uploadFileAndValidate,
    showColumnsAlert,
    closeColumnsAlert,
    previewErrorMessage
  };
};
export default useFeedbackFileUpload;
