// round number to 1 decimal place
export const getRoundNumber = (num: number | string): number => {
  if (typeof num === 'string') {
    num = Number(num);
  }
  return Math.round((num + Number.EPSILON) * 10) / 10;
};

export const calculatePercentage = (value: number, total: number): number => {
  return getRoundNumber((value / total) * 100);
};
