import React, { FC, useEffect, useState } from 'react';
import { IoCalendarSharp } from 'react-icons/io5';
import { format } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { cn } from '@/lib/utils';
import { Button, ButtonVariant } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';

interface IDatePickerWithRangeProps {
  onChange: (data: DateRange) => void;
  defaultValue?: DateRange;
  value?: DateRange;
  className?: string;
}

const DatePickerWithRange: FC<IDatePickerWithRangeProps> = ({
  className,
  onChange,
  defaultValue,
  value
}) => {
  const [date, setDate] = useState<DateRange | undefined>(value);

  useEffect(() => {
    if (!value?.from && !value?.to) {
      setDate(defaultValue);
      return;
    }
    setDate(value);
  }, [value]);

  const onSelect = (date: DateRange) => {
    setDate(date);
    if (date) {
      onChange(date);
    }
  };

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={ButtonVariant.OUTLINE}
            className={cn(
              'w-full justify-start text-left font-normal p-2',
              !date && 'text-muted-foreground'
            )}>
            <IoCalendarSharp className="h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, 'LLL dd, y')} -{' '}
                  {format(date.to, 'LLL dd, y')}
                </>
              ) : (
                format(date.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSelect={onSelect}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default DatePickerWithRange;
