import React, { FC, useCallback, useState } from 'react';
import { cn } from '@/lib/utils';
import { FaPencil } from 'react-icons/fa6';
import { TrashIcon } from '@icons';
import { Dropzone, FILE_TYPES } from '@ui/forms/Dropzone';
import { Button, ButtonVariant } from '@/components/ui/button';
import InputDialog from '@ui/dialogs/InputDialog';

export type FileType = File & { url: string };

interface IFileDropzone {
  file?: FileType | null;
  onFileSelect: (file: File) => void;
  onDeleteFile: () => void;
  scanFile?: (file: File) => Promise<boolean>;
  disabled?: boolean;
  acceptedFileType?: string;
}

const FileDropzone: FC<IFileDropzone> = ({
  file,
  onFileSelect,
  scanFile = () => Promise.resolve(true),
  onDeleteFile,
  disabled = false,
  acceptedFileType = FILE_TYPES.ALL
}) => {
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [acceptedFile, setAcceptedFile] = useState<File | null>(null);
  const [dropzoneKey, setDropzoneKey] = useState<number>(0);

  const onDelete = (): void => {
    setFileName('');
    onDeleteFile();
    setAcceptedFile(null);
    setDropzoneKey((prevKey) => prevKey + 1);
  };

  const onSelect = useCallback(
    async (file: File): Promise<void> => {
      onDelete();
      setIsValidating(true);
      const isValid = await scanFile(file);
      setIsValidating(false);
      if (!isValid) {
        onDelete();
        return;
      }
      if (file) {
        setFileName(file.name);
        setAcceptedFile(file);
        onFileSelect(file);
        setDropzoneKey((prevKey) => prevKey + 1);
      }
    },
    [scanFile, onFileSelect]
  );

  const onApplyName = useCallback(
    (name: string): void => {
      const fileName =
        name + acceptedFile?.name.slice(acceptedFile?.name.lastIndexOf('.'));
      if (acceptedFile) {
        setFileName(fileName);
        const file = new File([acceptedFile], fileName, {
          type: acceptedFile.type
        });
        onFileSelect(file);
      }
    },
    [acceptedFile, onFileSelect]
  );

  const nameOnly = fileName.split('.').slice(0, -1).join('.');

  return (
    <div className="w-full flex gap-2">
      <div className="flex-1">
        <Dropzone
          key={dropzoneKey}
          className="mb-6 h-40 bg-white"
          onSelect={onSelect}
          onDelete={onDelete}
          acceptedFileType={acceptedFileType}
          maxSize={1024 * 1024 * 25}
          fileURL={file?.url}
          fileType={file?.type}
          disabled={disabled}
          withPreview={false}
        />
        {isValidating && (
          <span className="text-darkCyan py-2 flex">Validating file...</span>
        )}
        {fileName && (
          <div className="flex justify-between items-center">
            <InputDialog
              defaultValue={nameOnly}
              title="File name"
              inputPlaceholder="Enter file name"
              buttonTitle="OK"
              onApply={onApplyName}>
              <div
                className={cn('flex items-center', {
                  'cursor-pointer hover:opacity-50': !disabled
                })}>
                <span className="break-all pr-3">{fileName}</span>
                {!disabled && <FaPencil />}
              </div>
            </InputDialog>
            <Button
              variant={ButtonVariant.SECONDARY}
              onClick={onDelete}
              className="p-3">
              <TrashIcon />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileDropzone;
