import React from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';

interface ILoadingDialogProps {
  description: string | JSX.Element;
  title: string;
  isOpen?: boolean;
  onClick?: () => void;
  buttonTitle?: string;
}

const LoadingDialog = ({
  description,
  title,
  isOpen = false,
  onClick = () => {},
  buttonTitle = 'Cancel'
}: ILoadingDialogProps) => {
  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-md">{title}</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription className="flex flex-col text-md justify-center h-[100px] items-center">
          <div className="pb-5">{description}</div>
        </AlertDialogDescription>
        <AlertDialogFooter>
          <Button className="px-6" onClick={onClick}>
            {buttonTitle}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default LoadingDialog;
