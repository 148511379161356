import { format, parse, startOfDay, endOfDay } from 'date-fns';

export type DateTimestampType = {
  startDate?: number;
  endDate?: number;
};

export const SHORT_DATE_FORMAT = 'dd/MM/yyyy';
export const FULL_DATE_FORMAT = 'dd/MM/yyyy HH:mm';

export const toClientFormat = (
  serverFormat: string | null,
  dateFormat: string = SHORT_DATE_FORMAT
): string | null => {
  const date = serverFormat
    ? parse(serverFormat, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date())
    : null;
  return date ? format(date, dateFormat) : null;
};

export const toServerFormat = (clientFormat: Date): string => {
  return format(new Date(clientFormat), "yyyy-MM-dd'T'HH:mm:ss.000'Z'");
};

export const dateStringFromTimestamp = (
  timestamp: number | string,
  dateFormat: string = SHORT_DATE_FORMAT
): string => {
  return format(new Date(Number(timestamp)), dateFormat);
};

export const dateToTimestamp = (date: Date): number => {
  return new Date(date).getTime();
};

export const changeDatesFormat = (
  date: DateTimestampType,
  dateSchema: string
) => {
  if (date?.startDate) {
    const dates: { lte?: number; gte?: number } = {
      gte: startOfDay(date.startDate).getTime()
    };
    dates.lte = endOfDay(date.endDate || date.startDate).getTime();

    return { [dateSchema]: dates };
  }
  return {};
};
