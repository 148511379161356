import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import DemoAPI from '@api/demoAPI';
import RaceBarChart from '@ui/charts/RaceBarChart';
import Spinner from '@ui/layout/Spinner';
import { Skeleton } from '@/components/ui/skeleton';
import { Button, ButtonVariant } from '@/components/ui/button';
import { Back } from '@icons';

enum Sentiments {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL'
}

const ConversationAnalysisPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const demoAPI = DemoAPI.getInstance();

  const { data: transcription } = useQuery({
    queryKey: ['transcription', id],
    queryFn: () => demoAPI.getTranscription(id as string)
  });

  const { data: fileSrc } = useQuery({
    queryKey: ['fileSrc', id],
    queryFn: () => demoAPI.getFile(id as string)
  });

  const sentimentsData = useMemo(() => {
    const data = {
      POSITIVE: 0,
      NEUTRAL: 0,
      NEGATIVE: 0
    };
    transcription?.sentiment_analysis_results?.forEach((sentiment: any) => {
      data[sentiment.sentiment as Sentiments] =
        data[sentiment.sentiment as Sentiments] + 1;
    });
    const barData = Object.keys(data).map((key) => ({
      name: key,
      data: [data[key as Sentiments]]
    }));
    return barData;
  }, [transcription]);

  const topics = useMemo((): string[] => {
    const topics: any = [];
    transcription?.iab_categories_result.results?.forEach((result: any) => {
      topics.push(...result.labels[0].label.split('>'));
    });
    return Array.from(new Set(topics));
  }, [transcription]);

  const backToConversationPage = (): void => {
    navigate('/conversation');
  };

  return (
    <div className="mb-5 mt-5 sm:ml-5 sm:w-full   ">
      <div className="mb-7">
        <Button
          className="z-9999 sm:absolute sm:left-[104px] left-0 mb-2 sm:mb-0 pl-4 sm:h-10 h-8 rounded-lg "
          onClick={backToConversationPage}
          variant={ButtonVariant.SECONDARY}>
          <Back />
          <span>Back</span>
        </Button>
        <h1 className="text-center text-2xl text-main ">
          Conversation analysis
        </h1>
      </div>
      {transcription ? (
        <div>
          <div>
            {fileSrc ? (
              <audio
                controls
                className=" flex md:w-full sm:justify-start md:justify-center sm:w-[calc(100vw-124px)] w-[calc(100vw-40px)] ">
                <source src={fileSrc} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            ) : (
              <Skeleton className="w-[calc(100vw-148px)] h-[54px] rounded-[100px] bg-gray-200" />
            )}
          </div>

          <div className="bg-white mt-5">
            <div className="flex flex-col items-center md:justify-center py-5">
              <div className="flex md:justify-between md:flex-row flex-col md:h-full h-[180vh] items-stretch sm:w-[calc(100vw-160px)] md:w-full w-[calc(100vw-40px)]">
                <div className="flex flex-col flex-1 border-r-2 px-5 items-start overflow-y-scroll h-[calc(100vh-300px)]">
                  <label htmlFor="" className="mb-2 font-bold">
                    Transcript:
                  </label>
                  <div className="mb-4">
                    {transcription.utterances?.map((utterance: any) => (
                      <div
                        className="flex flex-col items-start mb-4"
                        key={utterance.start}>
                        <span className="font-bold">
                          SPEAKER {utterance.speaker}
                        </span>
                        <div className="text-start">{utterance.text}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col flex-1 px-5 items-start overflow-y-scroll h-[calc(100vh-300px)]">
                  <div className="flex flex-col items-start">
                    <label htmlFor="" className="mb-2 font-bold">
                      Call Summary:
                    </label>
                    <div className="text-start">{transcription.summary}</div>
                  </div>
                  <div className="w-full">
                    <RaceBarChart
                      title="Tone identification"
                      wrapperClassName="border-none"
                      data={sentimentsData}
                    />
                  </div>
                  <div className="flex flex-col items-start ">
                    <label htmlFor="" className="mb-4 font-bold">
                      Topics:
                    </label>
                    <div className="flex gap-2 flex-wrap text-sm font-bold">
                      {topics?.map((topic: string) => (
                        <span
                          className="bg-gray-100 p-2 text-gray-500"
                          key={topic}>
                          {topic}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default ConversationAnalysisPage;
