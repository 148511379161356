import React, { FC, useCallback, useMemo, useState } from 'react';
import FileDropzone, { FileType } from '@ui/media/FileDropzone';
import { FILE_TYPES } from '@ui/forms/Dropzone';
import FileFieldsDialog from '@ui/dialogs/FileFieldsDialog';
import { Button } from '@/components/ui/button';
import { groupFileFields } from '@utils/fileHelper';
import { Option } from '@all-types/ui-types';
import { IDataSchema, IFileSchema } from '@all-types/csat-types';

interface IFileUploadSectionProps {
  onFileSubmit: (file: FileType, fields: IDataSchema | null) => Promise<void>;
  dataSchema: IFileSchema;
  fileFields: string[];
  isValid: boolean;
  uploadFileAndValidate: (file: File) => Promise<boolean>;
}

const FileUploadSection: FC<IFileUploadSectionProps> = ({
  fileFields,
  onFileSubmit,
  uploadFileAndValidate,
  isValid
}) => {
  const [file, setFile] = useState<FileType | null>(null);
  const [fields, setFields] = useState<IDataSchema | null>(null);

  const isFileSchemaEmpty = fileFields.length === 0;

  const onApplyFileFields = (fields: { [key: string]: Option }) => {
    console.log(fields);
    const groupedByType = groupFileFields(fields);
    console.log(groupedByType);
    setFields(groupedByType);
  };

  const onFileDelete = (): void => {
    setFile(null);
    setFields(null);
  };

  const onFileSelect = async (file: File): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFile(file);
  };

  const handleUploadButtonClick = useCallback(async () => {
    await onFileSubmit(file!, fields);
  }, [file, fields, onFileSubmit]);

  // TODO: Add validation for file and fields
  const isUploadButtonDisabled = useMemo(() => {
    if (isFileSchemaEmpty) {
      return !fields?.textField;
    }
    return !file || !isValid;
  }, [isFileSchemaEmpty, file, fields, isValid]);

  const showFieldsBlock = file && isFileSchemaEmpty;

  return (
    <div className="flex flex-col items-center">
      <h1 className="sm:text-start text-center text-2xl sm:p-10 pb-4 font-inter">
        Upload your file to begin
      </h1>
      {!isFileSchemaEmpty && (
        <div className="pb-4 text-left flex flex-col">
          Please make sure the uploaded file contains the following columns:
          <span className="font-bold text-darkCyan">
            {fileFields.join(', ')}
          </span>
        </div>
      )}
      <div className="w-full flex flex-col items-center justify-between gap-10">
        <div className="flex w-full flex-col items-center justify-between">
          <FileDropzone
            acceptedFileType={FILE_TYPES.EXCEL}
            onFileSelect={onFileSelect}
            onDeleteFile={onFileDelete}
            scanFile={uploadFileAndValidate}
            file={file}
          />
          {showFieldsBlock && (
            <>
              <span className="my-5">
                Map your columns to our schema fields before uploading the file
              </span>
              <FileFieldsDialog onApply={onApplyFileFields} file={file} />
            </>
          )}
          <Button
            className="px-4 mt-4 self-end"
            disabled={isUploadButtonDisabled}
            onClick={handleUploadButtonClick}>
            Upload
          </Button>
        </div>
        <hr className="w-full h-1 bg-main" />
      </div>
    </div>
  );
};

export default FileUploadSection;
