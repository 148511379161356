import React, { FC, memo, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ChartWrapper from '@ui/charts/ChartWrapper';

interface IPieChartProps {
  title: string;
  data: any;
  wrapperClassName?: string;
  isLoading?: boolean;
}

const PieChart: FC<IPieChartProps> = memo(function PieChart({
  title,
  data = [],
  wrapperClassName,
  isLoading = false
}) {
  const options = useMemo(
    () => ({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: '360px'
      },
      title: {
        text: title,
        align: 'left',
        style: {
          color: 'rgb(107 114 128)'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        },
        series: {
          borderWidth: 0,
          opacity: 0.9,
          colorByPoint: true,
          type: 'pie',
          size: '100%',
          innerSize: '80%',
          dataLabels: {
            enabled: true,
            crop: false,
            distance: '-10%',
            style: {
              fontWeight: 'bold',
              fontSize: '16px'
            },
            connectorWidth: 0
          }
        }
      },
      series: [
        {
          name: 'value',
          colorByPoint: true,
          data
        }
      ]
    }),
    [title, data]
  );

  return (
    <ChartWrapper className={wrapperClassName} isLoading={isLoading}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </ChartWrapper>
  );
});

export default PieChart;
