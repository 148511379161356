import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const badgeVariants = cva(
  'inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground',
        lightCyan:
          'bg-lightCyan border-main !text-darkCyan rounded-[100px] hover:bg-opacity/80 text-main font-normal',
        chichi:
          'bg-purple-10 text-purple-50 border-purple-80 rounded-[100px] hover:bg-opacity/80 font-normal',
        green:
          'bg-greenB-10 text-greenB-80 border-greenB-50 rounded-[100px] hover:bg-opacity/80 font-normal',
        yellow:
          'bg-yellow-10 text-yellow-80 border-yellow-50 rounded-[100px] hover:bg-opacity/80 font-normal',
        red: 'bg-redB-10 text-redB-80 border-redB-50 rounded-[100px] hover:bg-opacity/80 font-normal'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
