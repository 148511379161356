const mapArray = (value: any[]): string[] => {
  const values: string[] = [];
  value.forEach((value) => {
    if (typeof value === 'string') {
      values.push(value);
    } else if (Array.isArray(value)) {
      values.push(...getValueByType(value));
    } else if (typeof value === 'object') {
      values.push(...getValueByType(value));
    }
  });
  return values;
};

export const getValueByType = (value: any): string[] => {
  if (typeof value === 'string') {
    return [value];
  }
  if (Array.isArray(value)) {
    return mapArray(value);
  }
  if (typeof value === 'object') {
    const objectValues = Object.values(value);
    return mapArray(objectValues);
  }
  return [];
};
