import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import IntercomAPI from '@api/intercomAPI';
import Spinner from '@ui/layout/Spinner';

const IntercomAuthPage = () => {
  const [tokenRequested, setTokenRequested] = useState(false);

  const intercomAPI = IntercomAPI.getInstance();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  useEffect(() => {
    if (code && !tokenRequested) {
      const getToken = async () => {
        await intercomAPI.getToken(code);
      };
      getToken().catch(console.error);
      setTokenRequested(true);
    }
  }, [code, tokenRequested]);

  return (
    <div className="h-full py-16">
      <Spinner />
    </div>
  );
};

export default IntercomAuthPage;
