import { FC } from 'react';

interface IVideoPlayer {
  src?: any;
  controls: boolean;
  loop: boolean;
  width: string | number;
  height: string | number;
}

const VideoPlayer: FC<IVideoPlayer> = ({
  src,
  controls,
  width,
  height,
  loop
}) => {
  return (
    <video
      width={width}
      height={height}
      src={src}
      controls={controls}
      loop={loop}
    />
  );
};

export default VideoPlayer;
