import {
  ISentiment,
  ISentimentByData,
  ISentimentByDataValue
} from '@all-types/csat-types';

type GroupedData = {
  name: string;
  data: number[];
  color?: string;
}[];

export const SENTIMENT_COLORS: { [key: string]: string } = {
  Positive: 'rgb(1,180,4)',
  Neutral: 'rgb(255,222,0)',
  Negative: 'rgb(255,77,85)'
};

export function groupRatingData(data: ISentimentByData): GroupedData {
  const keys = ['Positive', 'Neutral', 'Negative'];

  // Initialize the result structure with those keys
  const result: GroupedData = keys.map((key) => ({
    name: key,
    data: [],
    color: SENTIMENT_COLORS[key]
  }));

  if (!data) {
    return result;
  }

  const sentimentsData = { ...data };
  if (Object.hasOwn(sentimentsData, 'total')) {
    delete sentimentsData.total;
  }
  // Map each entry's values to the corresponding key in the result
  Object.values(sentimentsData).forEach(
    ({ bySentiment }: ISentimentByDataValue) => {
      result.forEach((item) => {
        const sentimentValue = bySentiment?.[item.name as keyof ISentiment];
        item.data.push(sentimentValue || 0);
      });
    }
  );
  return result;
}
