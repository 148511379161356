import React from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';
import Layout from '@ui/layout/Layout';
import ErrorPage from '@pages/error-page';
import DashboardPage from '@pages/dashboard';
import ScriptConfig from '@pages/script-config';
import CallAnalytics from '@pages/call-analytics';
import TeamsScorecard from '@pages/teams-scorecard';
import CsatPage from '@pages/csat/index';
import FeedbackAnalysisPage from '@pages/csat/id';
import MainPage from '@pages/index';
import SpacesPage from '@pages/spaces';
import ConversationPage from '@pages/conversation';
import ConversationAnalysisPage from '@pages/conversation/id';
import IntegrationsPage from '@pages/integrations';
import IntercomAuthPage from '@pages/auth/intercom';
import SettingsPage from '@pages/settings';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route ErrorBoundary={ErrorPage} element={<Layout />}>
      <Route path="/" element={<MainPage />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/script-config" element={<ScriptConfig />} />
      <Route path="/teams-scorecard" element={<TeamsScorecard />} />
      <Route path="/call-analytics" element={<CallAnalytics />} />
      <Route path="/conversation" element={<ConversationPage />} />
      <Route path="/conversation/:id" element={<ConversationAnalysisPage />} />
      <Route path="/csat" element={<CsatPage />} />
      <Route path="/csat/:id" element={<FeedbackAnalysisPage />} />
      <Route path="/spaces" element={<SpacesPage />} />
      <Route path="/integrations" element={<IntegrationsPage />} />
      <Route path="/settings/*" element={<SettingsPage />} />
      <Route path="/auth-intercom" element={<IntercomAuthPage />} />
    </Route>
  )
);

export default router;
