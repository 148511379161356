import React from 'react';
import { cn } from '@/lib/utils';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { Button, ButtonVariant } from '@/components/ui/button';
import { ServiceType, services } from '@utils/servicesHelper';
import { Integrate } from '@icons';

const IntegrationsDialog = () => {
  const navigate = useNavigate();

  const goToIntegrations = (service: ServiceType) => {
    if (!service.disabled) {
      navigate('/integrations', { state: { active: service.name } });
    }
  };

  return (
    <Dialog>
      <DialogTrigger>
        <Button className="px-4 gap-8 text-darkCyan text-base hover:opacity-50 bg-lightCyan sm:w-72 sm:h-72 w-1/2 h-36 border-2 border-greenBorder  rounded-lg font-bold flex justify-between py-16 items-center cursor-pointer flex-col">
          <Integrate />
          Integrate with your feedback system
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-3xl font-inter">
        <DialogHeader>
          <DialogTitle className="flex justify-center flex-col">
            <span className="text-3xl pb-4 text-center w-full">
              Integrations
            </span>
            <div className="flex justify-center">
              <span className="text-sm text-gray-700 text-center max-w-[500px]">
                You can connect Unwink with applications to analyze your text
                data and automate business workflows.
              </span>
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="flex overflow-y-scroll gap-4 justify-center py-10">
          {services.map((service, index) => (
            <div
              key={index}
              className={cn(
                'flex flex-col items-center justify-center w-36 h-48 shadow-xl rounded-lg',
                {
                  'opacity-50': service.disabled,
                  'cursor-pointer': !service.disabled
                }
              )}
              onClick={() => goToIntegrations(service)}>
              <div className="w-[74px]">{service.icon}</div>
              <span className="mt-6 text-center">{service.name}</span>
            </div>
          ))}
        </div>
        <DialogFooter>
          <DialogClose>
            <Button
              variant={ButtonVariant.OUTLINE}
              className="border"
              size="sm">
              Cancel
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default IntegrationsDialog;
