import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import ServiceConnectBox from '@ui/integration/ServiceConnectBox';
import useIntegration from '@hooks/useIntegration';
import { SpaceContext, SpaceContextType } from '@context/contexts';
import { services, ServiceType } from '@utils/servicesHelper';

const IntegrationsPage = () => {
  const [selectedServiceIndex, setSelectedServiceIndex] = useState<
    number | null
  >(null);

  const activeServiceRef = useRef(false);
  const {
    allowProcessing,
    feedbackStatus,
    isConnected,
    isPending,
    isFailed,
    isRunning,
    isSuccessful,
    integrationStatus,
    onStartProcessing,
    onConnect
  } = useIntegration();
  const { spaces, currentSpace, onSpaceChange, onCreateSpace } =
    useContext<SpaceContextType>(SpaceContext);
  const location = useLocation();

  const activeService = location.state?.active;
  const currentSpaceId = currentSpace?.id;

  const onServiceInfoOpen = useCallback(
    (service: ServiceType, index: number) => {
      if (service.disabled) {
        return;
      }
      const serviceSpace = spaces.find((space) => space.type === service.name);
      if (serviceSpace && serviceSpace.id !== currentSpaceId) {
        onSpaceChange(serviceSpace.id);
      }
      setSelectedServiceIndex((prevIndex) => {
        return prevIndex === index ? null : index;
      });
    },
    [spaces, currentSpaceId]
  );

  const createSpaceAndConnect = async (name: string) => {
    const created = await onCreateSpace(name);
    if (created) {
      onConnect();
    }
  };

  useLayoutEffect(() => {
    if (activeService && !activeServiceRef.current) {
      activeServiceRef.current = !!activeService;
      const serviceIndex = services.findIndex(
        (service) => service.name === activeService
      );
      if (serviceIndex !== -1) {
        onServiceInfoOpen(services[serviceIndex], serviceIndex);
      }
    }
  }, [activeService, onServiceInfoOpen]);

  const serviceList = useMemo(() => {
    return services.map((service, index) => {
      const onServiceClick = () => {
        onServiceInfoOpen(service, index);
      };

      return (
        <div
          key={service.name}
          onClick={onServiceClick}
          className={cn(
            'flex flex-col items-center justify-center w-36 h-48 shadow-xl duration-300 rounded-lg mx-4 my-2 hover:shadow-2xl transition-transform',
            {
              'opacity-50': service.disabled,
              'cursor-pointer': !service.disabled,
              'border-2 border-main transform -translate-y-2':
                selectedServiceIndex === index
            }
          )}>
          {service.icon}
          <span className="mt-6 text-center">{service.name}</span>
        </div>
      );
    });
  }, [services, selectedServiceIndex, onServiceInfoOpen]);

  return (
    <div className="flex flex-col pt-5 font-inter">
      <div className="flex flex-col justify-center">
        <span className="text-3xl pb-4 text-center w-full">Integrations</span>
        <div className="flex justify-center">
          <span className="text-sm text-gray-700 text-center max-w-[500px]">
            You can connect Unwink with applications to analyze your text data
            and automate business workflows.
          </span>
        </div>
      </div>
      <div className="flex overflow-y-scroll gap-4 justify-center py-10 px-20">
        <div className="flex flex-wrap justify-center">{serviceList}</div>
      </div>
      {services.map((service, index) => (
        <ServiceConnectBox
          key={service.name}
          name={service.name}
          icon={service.smallIcon}
          isUsed={currentSpace?.type !== null}
          isSelected={selectedServiceIndex === index}
          isConnected={isConnected && !isPending}
          onConnect={onConnect}
          createSpaceAndConnect={createSpaceAndConnect}
          isLoading={isPending}
          isFailed={isFailed}
          isRunning={isRunning}
          isSuccessful={isSuccessful}
          integrationStatus={integrationStatus}
          feedbackStatus={feedbackStatus}
          onStartProcessing={onStartProcessing}
          allowProcessing={allowProcessing}
        />
      ))}
    </div>
  );
};

export default IntegrationsPage;
