import React, { FC } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ChartWrapper from '@ui/charts/ChartWrapper';

interface ILineChartProps {
  data: any;
  wrapperClassName?: string;
}

const LineChart: FC<ILineChartProps> = ({ data, wrapperClassName }) => {
  const options = {
    chart: {
      type: 'line'
    },
    title: {
      text: 'Total visitors',
      subtitle: 'Last 4 Weeks'
    },
    xAxis: {
      categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4']
    },
    yAxis: {
      title: {
        text: 'Values'
      }
    },
    series: [
      {
        name: 'Values',
        data
      }
    ]
  };
  return (
    <ChartWrapper className={wrapperClassName}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </ChartWrapper>
  );
};

export default LineChart;
