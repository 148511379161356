import React, { FC } from 'react';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';

interface ISkeletonGroupProps {
  className?: string;
  count?: number;
}
const SkeletonGroup: FC<ISkeletonGroupProps> = ({ className, count = 1 }) => {
  return (
    <div className={cn('flex flex-col w-full h-full gap-5', className)}>
      {Array.from({ length: count }).map((_, index) => (
        <div className="flex flex-col gap-3" key={index}>
          <Skeleton className="w-full h-10 rounded-lg bg-gray-200" />
          <>
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton
                key={index}
                className="w-4/5 h-5 rounded-lg bg-gray-200"
              />
            ))}
          </>
        </div>
      ))}
    </div>
  );
};

export default SkeletonGroup;
