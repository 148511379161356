import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import startCase from 'lodash/startCase';
import { IFilters, IInsightPrompt } from '@all-types/csat-types';
import CsatAPI from '@api/csatAPI';
import { getValueByType } from '@utils/structureHelper';
import BlinkingDots from '@ui/blinkingDots/BlinkingDots';
import { SpaceContext, SpaceContextType } from '@context/contexts';

export type MessageType = {
  message: JSX.Element | string | string[];
  sentTime: string;
  sender: string;
};

interface IFeedbackAnalysisProps {
  id: string;
  appliedFilters?: Partial<IFilters>;
}

function list(arr: any) {
  if (typeof arr === 'string') {
    return arr;
  }

  if (typeof arr === 'number') {
    return arr;
  }

  if (Array.isArray(arr)) {
    return arr.map((item) => {
      return (
        <div className="flex items-start flex-col pl-5 text-left" key={item}>
          {list(item)}
        </div>
      );
    });
  }

  return Object.entries(arr).map(([key, value]) => {
    return (
      <div className="flex items-start flex-col pl-5" key={key}>
        <h4 className="font-bold inline-block mb-0 mt-0">{startCase(key)}:</h4>{' '}
        {list(value)}
      </div>
    );
  });
}

const defaultMessages: MessageType[] = [
  {
    message: 'Ask me whatever you want.',
    sentTime: new Date().toISOString(),
    sender: 'AI'
  }
];

const useInsights = ({ id, appliedFilters }: IFeedbackAnalysisProps) => {
  const csatAPI = CsatAPI.getInstance();
  const [messages, setMessages] = useState<MessageType[]>(defaultMessages);

  const { currentSpace } = useContext<SpaceContextType>(SpaceContext);
  const currentSpaceId = currentSpace?.id;

  const {
    data: insightsResponse,
    isLoading: isInsightsLoading,
    refetch: refetchInsights
  } = useQuery({
    queryKey: ['insights', currentSpaceId, appliedFilters],
    queryFn: () => csatAPI.getInsights(currentSpaceId!, appliedFilters),
    enabled: false
  });

  const askAI = useCallback(
    async (question: string) => {
      setMessages((prev) => [
        ...prev,
        {
          message: <BlinkingDots />,
          sentTime: new Date().toISOString(),
          sender: 'AI'
        }
      ]);

      const response = await csatAPI.getInsightsByQuestion(
        currentSpaceId!,
        question,
        appliedFilters
      );

      let answer: string | string[] = '';
      if (
        typeof response === 'object' &&
        Object.keys(response as IInsightPrompt).length === 0
      ) {
        answer = 'I am not sure what you are asking.';
      } else if (response) {
        answer = getValueByType(response);
      }
      if (answer === '') {
        answer = 'I am not sure what you are asking.';
      }
      setMessages((prev) => [
        ...prev.slice(0, -1),
        { message: answer, sentTime: new Date().toISOString(), sender: 'AI' }
      ]);
    },
    [appliedFilters]
  );

  const sendMessage = async (question: string) => {
    if (question) {
      setMessages((prev) => [
        ...prev,
        {
          message: question,
          sentTime: new Date().toISOString(),
          sender: 'You'
        }
      ]);
      await askAI(question);
    }
  };

  const insights = useMemo(() => {
    if (typeof insightsResponse === 'string') {
      return insightsResponse;
    }
    if (insightsResponse) {
      return <div className="flex flex-col">{list(insightsResponse)}</div>;
    }
    return null;
  }, [insightsResponse]);

  return {
    messages,
    insights,
    isInsightsLoading,
    refetchInsights,
    sendMessage
  };
};

export default useInsights;
