import React, { FC } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ChartWrapper from '@ui/charts/ChartWrapper';

interface IStackedBarChartProps {
  data?: any;
  wrapperClassName?: string;
}

const StackedBarChart: FC<IStackedBarChartProps> = ({ wrapperClassName }) => {
  const chartOptions = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Visitors by Gender over Time'
    },
    xAxis: {
      categories: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    },
    yAxis: {
      title: {
        text: 'Number of Visitors'
      }
    },
    plotOptions: {
      series: {
        stacking: 'normal'
      }
    },
    series: [
      {
        name: 'Man',
        data: [100, 120, 130, 110, 90, 105, 115, 150, 140, 135, 120, 130]
      },
      {
        name: 'Woman',
        data: [80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135]
      }
    ]
  };

  return (
    <ChartWrapper className={wrapperClassName}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </ChartWrapper>
  );
};

export default StackedBarChart;
