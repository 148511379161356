import React, { FC } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import TableInfo from '@ui/table/TableInfo';
import { TableType } from '@utils/tableType';

interface IFeedbacksTabProps {
  tableData: any[];
  columns: ColumnDef<TableType>[];
  rowCount?: number;
  pagination?: {
    pageIndex: number;
    pageSize: number;
  };
  setPagination?: any;
}

const FeedbacksTab: FC<IFeedbacksTabProps> = ({
  rowCount,
  tableData,
  columns,
  pagination,
  setPagination
}) => {
  return (
    <div className="flex gap-5 overflow-x-scroll">
      {tableData.length ? (
        <TableInfo
          data={tableData}
          columns={columns}
          title="Samples"
          rowCount={rowCount}
          pagination={pagination}
          manualPagination
          setPagination={setPagination}
        />
      ) : (
        <div className="h-[calc(100vh-185px)] bg-white w-full flex flex-col justify-center items-center">
          <span className="text-gray-700 text-2xl pb-8 flex justify-center">
            No feedbacks found
          </span>
        </div>
      )}
    </div>
  );
};

export default FeedbacksTab;
